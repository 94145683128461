import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ILink } from '@ncg/data';

@Component({
    selector: 'ncg-top-menu',
    standalone: true,
    template: `
        <nav class="top-menu">
            <a
                class="top-menu__link"
                *ngFor="let link of links"
                [href]="link.isExternal ? link.url : null"
                [routerLink]="!link.isExternal ? link.url : null"
                [attr.target]="link.target"
                rel="noopener"
            >
                {{ link.name }}
            </a>
        </nav>
    `,
    styleUrls: ['./top-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

    imports: [NgForOf, NgIf, RouterLink],
})
export class TopMenuComponent {
    @Input()
    public links?: ILink[];
}
