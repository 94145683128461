import { Pipe, PipeTransform } from '@angular/core';
import { BodyStyleViewModel, CapacityViewModel, CarModelV2, DataEntityWithResources, EngineTypeViewModel, TrimViewModel } from '@ncg/data';
import { catchError, map, Observable, of } from 'rxjs';
import { SettingsService } from '../../core/settings.service';
import { ConfiguratorQueryParams } from '../configurator';
import { getIdByByPrice } from './by-price.util';

/**
 * Returns cheapest exterior color for variant/powertrain combo.
 * Pass in EITHER bodyStyle, engineType, capacity or trim for variant selection (As in: "ONLY ONE of them").
 */
@Pipe({
    name: 'exteriorColorByVariant',
    standalone: true,
    pure: true,
})
export class ExteriorColorByVariantPipe implements PipeTransform {
    constructor(private readonly settingsService: SettingsService) {}
    transform(
        model: CarModelV2,
        params: ConfiguratorQueryParams,
        bodyStyle?: BodyStyleViewModel,
        engineType?: EngineTypeViewModel,
        capacity?: CapacityViewModel,
        trim?: TrimViewModel
    ): Observable<DataEntityWithResources | undefined> {
        if (bodyStyle) {
            engineType = bodyStyle?.engineTypes?.find((e) => e.id === params.enginetype) ?? bodyStyle?.engineTypes?.[0];
        }

        if (engineType) {
            capacity = bodyStyle?.engineTypes?.find((e) => e.id === params.capacity) ?? engineType?.capacities?.[0];
        }

        if (capacity) {
            trim = capacity?.trims?.find((t) => t.id === params.trim) ?? capacity?.trims?.[0];
        }

        if (!trim) {
            console.error('ERROR: ExteriorColorByVariantPipe missing trim data.');
            return of(undefined);
        }

        const variant = trim?.variants?.find((v) => v.powerTrainId === params.powertrain) ?? trim?.variants?.[0];

        return this.settingsService.settings$.pipe(
            map(({ currency }) => {
                const cheapestExteriorIdInVariant = getIdByByPrice(
                    variant?.commercialColourOptionExteriorIds,
                    model?.commercialColourOptionsExterior,
                    `CommercialColourOptionRetailSellingPrice`,
                    currency,
                    'lowest'
                );
                const cheapestExteriorInVariant = model?.commercialColourOptionsExterior?.[cheapestExteriorIdInVariant];
                if (cheapestExteriorInVariant) {
                    return cheapestExteriorInVariant;
                }
                return undefined;
            }),
            catchError((e) => {
                console.warn('WARNING: Could not find exterior', e);
                return of(undefined);
            })
        );
    }
}
