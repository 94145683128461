import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILink, PageResponse } from '@ncg/data';
import { combineLatest, Subject, takeWhile } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { MetaService } from '../core/meta.service';
import { PreviewService } from '../core/preview.service';

import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { NavigationService } from '../navigation/navigation.service';
import { SearchDialogService } from '../search/search-dialog.service';
import { SidePanelService } from '../side-panel/side-panel.service';
import { RecentlyViewedCarsSpotService } from '../spots/recently-viewed-cars-spot/recently-viewed-cars-spot.service';
import { ImageUrl } from '../utils/helpers/image-helper';
import { ErrorPageComponent } from './error-page/error-page.component';
import { pageComponents } from './page-components';

@Component({
    selector: 'ncg-page',
    template: `<article class="page__content">
        <ng-template ncgDynamicPageComponent [component]="component" [inputs]="inputs"></ng-template>
    </article>`,
})
export class PageComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    public component: any;
    public inputs: { data?: any } = {};
    public quicklinksSearch: ILink[] = [];

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly settingsService: SettingsService,
        private readonly navigationService: NavigationService,
        private readonly metaService: MetaService,
        private readonly trackingService: TrackingService,
        private readonly recentlyViewedCars: RecentlyViewedCarsSpotService,
        private readonly searchDialogService: SearchDialogService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly sidepanelService: SidePanelService,
        private readonly previewService: PreviewService,
        private readonly router: Router,
        private readonly location: Location
    ) {}

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.featureDetectionService.isFirstPageLoad = false;
    }

    public ngOnInit() {
        // Read the content from the route snapshot (this 'content' is the name of the resolve)
        let data: PageResponse = this.activatedRoute.snapshot.data.content;

        if (!data) {
            return;
        }

        if (data.preview) {
            this.previewService.setPreviewPageContext(data.id, data.published, data.url);
            // Update url to reflect preview state, so users will remain in preview mode
            this.location.replaceState(
                this.router.serializeUrl(
                    this.router.createUrlTree([], {
                        queryParams: this.previewService.getPreviewQueryParams(),
                        queryParamsHandling: 'merge',
                        preserveFragment: true,
                    })
                ),
                undefined,
                this.location.getState()
            );
        } else {
            this.previewService.exitPreview();
        }

        this.settingsService.initSettings(data.culture, data.preview);

        let template = 'errorPage';

        switch (data.template) {
            case 'notFoundPage':
            case 'standardCampaignPage':
                template = 'standardPage';
                break;

            case 'campaignPage':
            case 'informationPage':
                template = 'modelPage';
                break;

            case 'campaignSubpage':
            case 'informationSubpage':
                template = 'modelSubpage';
                break;
            case 'sidepanel':
                this.sidepanelService.openContent(data.url);
                template = 'standardPage';
                break;
            case 'configuratorPage':
                template = 'configuratorPage';
                break;
            case 'configuratorModelPage':
                template = 'configuratorModelPage';
                break;
            case 'serviceBookingPage':
                template = 'serviceBookingPage';
                break;
            case 'widePage':
                template = 'widePage';
                break;
            case 'favoritesPage':
                template = 'favoritesPage';
                break;
            default:
                template = data.template;
        }

        // Find the ComponentClass of the desired pageComponent (based on template)
        this.component = pageComponents.find((component) => component.ref === template);

        if (!this.component) {
            this.component = ErrorPageComponent;
            data = {
                ...data,
            };
        }

        this.inputs['data'] = data;

        this.navigationService.isFrontPage$.next(template === 'rootPage');
        this.navigationService.currentPageId$.next(data.id);
        this.navigationService.languageLinks$.next(data.languageLinks);
        this.navigationService.breadcrumb$.next(data.breadcrumb);

        // SEO-related information extracted from the data object
        this.metaService.changePage(template);
        this.metaService.setTitle(data.meta?.title);
        this.metaService.setDescription(data.meta?.description);
        this.metaService.setKeywords(data.meta?.keywords);
        this.metaService.setNoIndex(data.meta?.excludeFromRobots);
        this.metaService.setCanonical(data.meta?.canonical);
        this.metaService.setLanguageVariants(data.languageLinks || {});

        // Set CMS page ID for impact hook extension
        this.metaService.setImpactHook(data.id);

        if (!this.featureDetectionService.isFirstPageLoad) {
            // Call google tag manager
            this.trackingService.trackVirtualPageview();

            // Reload CookieInformation consent
            this.trackingService.reloadCookieInformation();
        }

        // Open graph
        if (template !== 'productPage') {
            this.settingsService
                .get()
                .pipe(take(1), takeUntil(this.unsubscribe))
                .subscribe((settings) => {
                    this.metaService.setOpenGraph({
                        title: data.meta?.title || '',
                        image: data.meta?.image
                            ? {
                                  url: ImageUrl(data.meta.image, { width: 1200, height: 630, mode: 'crop' }),
                                  altText: data.meta.image.altText,
                                  width: '1200',
                                  height: '630',
                              }
                            : undefined,
                        type: 'website',
                        author: settings.companyName,
                    });
                });
        }

        // Vehicle tracking
        if (data.meta?.visibleModels) {
            this.trackingService.trackVehicle(data.meta.visibleModels.filter((x) => !!x.pimModelId).map((x) => x.pimModelId ?? ''));
        } else if ((data.template === 'modelPage' || data.template === 'modelSubpage') && !!data.pimModelId) {
            this.trackingService.trackVehicle([data.pimModelId]);
        } else {
            this.trackingService.trackVehicle([]);
        }

        if (data.template === 'productPage') {
            this.recentlyViewedCars.trackLastViewedVehicle(data);
        }

        // Microsoft Clarity - Start tracking once consent is given and script us loaded.
        combineLatest([
            this.trackingService.isClarityLoaded$.pipe(takeWhile((isLoaded) => !isLoaded, true)),
            this.trackingService.statisticConsentGiven(),
        ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([isLoaded, hasConsent]) => {
                if (isLoaded) {
                    if (hasConsent) {
                        this.trackingService.initClarityTracking();
                    } else {
                        this.trackingService.initClarityTracking(true);
                    }
                }
            });

        // Microsoft Clarity - Load script.
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                if (settings.clarityId) {
                    this.trackingService.loadClarityScript(settings.clarityId);
                }
            });

        // Hotjar tracking - initialize only if statistics consent is given
        combineLatest([this.settingsService.get().pipe(take(1)), this.trackingService.statisticConsentGiven()])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([settings, consent]) => {
                const { hjId } = settings;

                if (consent && hjId) {
                    this.trackingService.initHotjar(parseInt(hjId));
                }
            });

        this.openSearchDialog();
    }

    private openSearchDialog() {
        this.activatedRoute.queryParamMap.pipe(take(1), takeUntil(this.unsubscribe)).subscribe((paramMap) => {
            const searchParam = paramMap.get('search');
            if (searchParam) {
                this.searchDialogService.openSearchDialog();
            }
        });
    }
}
