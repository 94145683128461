/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
    street?: string | null;
    zipcode?: string | null;
    city?: string | null;
    country?: string | null;
    /** @format double */
    lat?: number;
    /** @format double */
    lng?: number;
}

export interface Aggregations {
    locations?: NgcApiLocation[] | null;
    categories?: Record<string, number>;
}

export interface BodyStyleViewModel {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    resourcePackShot?: DataEntity[] | null;
    resource360?: DataEntity[] | null;
    resources?: DataListEntity[] | null;
    engineTypes?: EngineTypeViewModel[] | null;
}

export interface Business {
    /** @format int32 */
    id?: number;
    name?: string | null;
    identifier?: string | null;
}

export interface CapacityViewModel {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    resourcePackShot?: DataEntity[] | null;
    resource360?: DataEntity[] | null;
    resources?: DataListEntity[] | null;
    trims?: TrimViewModel[] | null;
}

export interface CarFilterViewModel {
    id?: string | null;
    feedSource?: string | null;
    chassisID?: string | null;
    regNo?: string | null;
    displayName?: string | null;
    make?: string | null;
    model?: string | null;
    series?: string | null;
    color?: string | null;
    bodyTypes?: string[] | null;
    /** @format int32 */
    year?: number;
    variant?: string | null;
    /** @format int32 */
    mileage?: number;
    /** @format int32 */
    numberOfDoors?: number;
    vatType?: CarVatType;
    /** @format int32 */
    price?: number;
    priceType?: CarPriceType;
    /** @format int32 */
    cashPrice?: number | null;
    /** @format int32 */
    leasingPrice?: number | null;
    pictures?: string[] | null;
    /** @format double */
    motor?: number | null;
    gearType?: string | null;
    premium?: string | null;
    isFeatured?: boolean;
    isDemo?: boolean;
    culture?: string | null;
    currency?: string | null;
    propellant?: string | null;
    /** @format double */
    kmPerLiter?: number;
    dealerName?: string | null;
    /** @format int32 */
    range?: number | null;
    /** @format date-time */
    modifiedDate?: string;
}

export interface CarFilterViewModelSearchResultViewModel {
    /** @format int64 */
    total?: number;
    premiumTextDefault?: string | null;
    premiumText?: Record<string, string | null>;
    facets?: Record<string, Facet[] | null>;
    hits?: CarFilterViewModel[] | null;
}

export interface CarModel {
    id?: string | null;
    title?: string | null;
    /** @format date-time */
    lastModified?: string;
    trims?: TrimViewModel[] | null;
    powerTrains?: Record<string, DataEntity>;
    partnerProducts?: Record<string, DataEntityWithResources>;
    accessories?: Record<string, DataEntityWithResources>;
    commercialColourOptionsExterior?: Record<string, DataEntityWithResources>;
    commercialColourOptionsInterior?: Record<string, DataEntityWithResources>;
    optionalOptions?: Record<string, DataListDataListEntityWithResources>;
    accessoryIds?: string[] | null;
    standardOptions?: Record<string, DataEntity>;
    eTag?: string | null;
}

export interface CarModelV2 {
    title?: string | null;
    /** @format date-time */
    lastModified?: string;
    bodyStyles?: BodyStyleViewModel[] | null;
    powerTrains?: Record<string, DataEntity>;
    partnerProducts?: Record<string, DataEntityWithResources>;
    accessories?: Record<string, DataEntityWithResources>;
    commercialColourOptionsExterior?: Record<string, DataEntityWithResources>;
    commercialColourOptionsInterior?: Record<string, DataEntityWithResources>;
    optionalOptions?: Record<string, DataListDataListEntityWithResources>;
    accessoryIds?: string[] | null;
    standardOptions?: Record<string, DataEntity>;
    eTag?: string | null;
}

export enum CarPriceType {
    RetailPrice = 'RetailPrice',
    Leasing = 'Leasing',
    CallForPrice = 'CallForPrice',
    CallForLeasing = 'CallForLeasing',
}

export enum CarVatType {
    Unknown = 'Unknown',
    WithVat = 'WithVat',
    WithoutVat = 'WithoutVat',
}

export interface CarViewModel {
    id?: string | null;
    feedSource?: string | null;
    chassisID?: string | null;
    regNo?: string | null;
    displayName?: string | null;
    make?: string | null;
    model?: string | null;
    series?: string | null;
    variant?: string | null;
    /** @format date-time */
    registrationDate?: string | null;
    /** @format date-time */
    inspectionDate?: string | null;
    /** @format date-time */
    modifiedDate?: string;
    /** @format int32 */
    mileage?: number;
    /** @format int32 */
    year?: number;
    /** @format int32 */
    productionYear?: number;
    /** @format int32 */
    production?: number;
    color?: string | null;
    /** @format int32 */
    numberOfDoors?: number;
    /** @format int32 */
    numberOfPassengers?: number;
    /** @format int32 */
    numberOfSeats?: number;
    bodyTypes?: string[] | null;
    /** @format int32 */
    weight?: number;
    /** @format int32 */
    totalWeight?: number;
    /** @format int32 */
    trailerWeight?: number;
    /** @format int32 */
    trunkSize?: number;
    /** @format int32 */
    length?: number;
    /** @format int32 */
    width?: number;
    /** @format int32 */
    height?: number;
    /** @format int32 */
    numberOfAirbags?: number;
    comment?: string | null;
    equipmentList?: string[] | null;
    pictures?: string[] | null;
    /** @format int32 */
    greenTax?: number;
    /** @format int32 */
    weightTax?: number;
    /** @format int32 */
    registrationTax?: number;
    gearType?: string | null;
    /** @format int32 */
    numberOfGears?: number;
    driveWheels?: string | null;
    propellant?: string | null;
    secondaryPropellant?: string | null;
    /** @format double */
    acceleration0To100?: number;
    /** @format int32 */
    topSpeed?: number;
    /** @format double */
    co2Emissions?: number;
    /** @format double */
    motor?: number | null;
    /** @format int32 */
    cylinders?: number;
    /** @format int32 */
    cylinderVolumeCC?: number;
    /** @format int32 */
    gasTankMax?: number;
    /** @format double */
    kmPerLiter?: number;
    /** @format int32 */
    effect?: number;
    /** @format int32 */
    effectInNm?: number;
    /** @format int32 */
    effectInKw?: number;
    /** @format int32 */
    electricConsumption?: number | null;
    /** @format int32 */
    range?: number | null;
    /** @format double */
    batterySize?: number | null;
    /** @format int32 */
    newPrice?: number | null;
    /** @format int32 */
    price?: number;
    /** @format int32 */
    cashPrice?: number | null;
    /** @format int32 */
    leasingPrice?: number | null;
    priceType?: CarPriceType;
    vatType?: CarVatType;
    leasingAudience?: string | null;
    leasingType?: string | null;
    /** @format int32 */
    leasingDuration?: number;
    /** @format int32 */
    leasingDownPayment?: number;
    /** @format int32 */
    leasingResidualValue?: number;
    /** @format int32 */
    leasingTotalPayment?: number;
    /** @format int32 */
    leasingYearlyMileage?: number;
    leasingIncludesInsurance?: boolean;
    leasingServiceAndMaintenance?: string | null;
    leasingDisclaimer?: string | null;
    premium?: string | null;
    /** @format int32 */
    dealerId?: number;
    isFeatured?: boolean;
    serviceBook?: boolean;
    video?: string | null;
    isDemo?: boolean;
    culture?: string | null;
    currency?: string | null;
    dealerName?: string | null;
    vehicleSourceId?: string | null;
}

export interface Category {
    /** @format int32 */
    id?: number;
    name?: string | null;
    code?: string | null;
}

export interface Data {
    key?: string | null;
    value?: any;
}

export interface DataEntity {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
}

export interface DataEntityWithResources {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    resourcePackShot?: DataEntity[] | null;
    resource360?: DataEntity[] | null;
    resources?: DataListEntity[] | null;
}

export interface DataField {
    fieldTypeId?: string | null;
    dataType?: string | null;
    multiValue?: boolean;
    data?: Data;
}

export interface DataListDataListEntityWithResources {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataListField[] | null;
    resourcePackShot?: DataListEntity[] | null;
    resource360?: DataListEntity[] | null;
    resources?: DataListEntity[] | null;
}

export interface DataListEntity {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataListField[] | null;
}

export interface DataListField {
    fieldTypeId?: string | null;
    dataType?: string | null;
    multiValue?: boolean;
    data?: Data[] | null;
}

export interface EngineTypeViewModel {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    resourcePackShot?: DataEntity[] | null;
    resource360?: DataEntity[] | null;
    resources?: DataListEntity[] | null;
    capacities: CapacityViewModel[] | null;
}

export interface Facet {
    key?: string | null;
    /** @format int64 */
    count?: number;
    selected?: boolean;
}

export interface MakeCountViewModel {
    key?: string | null;
    makes?: Facet[] | null;
}

export interface NcgApiLocationResponse {
    /** @format int32 */
    total?: number;
    locale?: string | null;
    aggregations?: Aggregations;
}

export interface NgcApiLocation {
    /** @format int32 */
    id?: number;
    name?: string | null;
    store_code?: string | null;
    email?: string | null;
    phone?: string | null;
    lms?: string | null;
    used_car_id?: string | null;
    externalDealerSite?: string | null;
    external_online_servicebooking?: string | null;
    onlineWorkshopBooking?: boolean;
    brands?: string[] | null;
    address?: Address;
    distance?: string | null;
    business?: Business;
    distributor?: Business;
    category?: Category;
    opening_hours?: OpeningHours[] | null;
}

export interface OpeningHours {
    open_day?: string | null;
    open_day_label?: string | null;
    open_time?: string | null;
    close_day?: string | null;
    close_day_label?: string | null;
    close_time?: string | null;
    /** @format int32 */
    sorting?: number;
}

export interface ProblemDetails {
    type?: string | null;
    title?: string | null;
    /** @format int32 */
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
}

export interface SearchResultModel {
    make?: string | null;
    /** @format int64 */
    total?: number;
    /** @format int64 */
    count?: number;
    hits?: CarFilterViewModel[] | null;
}

export interface SearchViewModel {
    hits?: SearchResultModel[] | null;
}

export interface TrimViewModel {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    variants?: VariantViewModel[] | null;
    standardOptionsDisplayIds?: string[] | null;
    standardOptionsHighlightsIds?: string[] | null;
    partnerProductIds?: string[] | null;
    accessoryIds?: string[] | null;
}

export interface VariantViewModel {
    id?: string | null;
    title?: string | null;
    entityType?: string | null;
    fields?: DataField[] | null;
    resourcePackShot?: DataEntity[] | null;
    resource360?: DataEntity[] | null;
    resources?: DataEntity[] | null;
    powerTrainId?: string | null;
    accessoryIds?: string[] | null;
    commercialColourOptionExteriorIds?: string[] | null;
    commercialColourOptionInteriorIds?: string[] | null;
    optionalOptionIds?: string[] | null;
}
