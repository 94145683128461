import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TabsSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-tabs-spot',
    styleUrls: ['./tabs-spot.component.scss'],
    template: `
        <div class="tabs-spot" *ngIf="data?.items?.length" ncgLoadIn>
            <ncg-tab-group>
                <ncg-tab *ngFor="let item of data.items; let isFirst = first" [label]="item.title" [isActive]="isFirst">
                    <div
                        class="tab"
                        [ngClass]="{
                            'tab--dark': data.theme === 'Dark',
                            'tab--light': data.theme === 'Light'
                        }"
                    >
                        <div class="tab__image">
                            <a
                                *ngIf="item.link && item.link.url; else image"
                                [href]="item.link.url"
                                [attr.title]="item.linkHoverTitle"
                                [attr.aria-label]="item.linkHoverTitle"
                                [attr.target]="item.link.isExternal ? '_blank' : ''"
                                ncgInterceptLinks
                            >
                                <ng-container *ngTemplateOutlet="image"></ng-container>
                            </a>
                            <ng-template #image>
                                <img
                                    *ngIf="this.item.image"
                                    class="tab__image-img chromatic-ignore"
                                    [src]="this.item.image | srcset: { retina: false, imageOptions: { width: 580 } }"
                                    [srcset]="item.image | srcset: { widths: [370, 465, 580] }"
                                    [alt]="this.item.image.altText || this.item.title || ''"
                                    sizes="(min-width: 1250px) 580px, (min-width: 1024px) 465px, (min-width: 768px) 370px, 100vw"
                                    loading="lazy"
                                    ncgImageLoad
                                />
                            </ng-template>
                        </div>
                        <div class="tab__content">
                            <ncg-spot-headline *ngIf="item.heading" [headlineClass]="'tab__heading'">
                                {{ item.heading }}
                            </ncg-spot-headline>
                            <ncg-rich-text [html]="item.text"></ncg-rich-text>
                            <ncg-button
                                *ngIf="item.link && item.showButton"
                                [title]="item.link.name"
                                [buttonClass]="'button is-secondary'"
                                [link]="item.link"
                                [linkHoverTitle]="item.linkHoverTitle"
                            ></ncg-button>
                        </div>
                    </div>
                </ncg-tab>
            </ncg-tab-group>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsSpotComponent extends SpotBaseDirective {
    static ref = 'tabs';

    @Input() data: TabsSpot;
}
