import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, QueryParamsHandling, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import stringify from 'fast-safe-stringify';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfiguratorQueryParams, ConfiguratorTab } from '../../configurator/configurator';
import { configuratorParamsWhiteList, multiParamSeparator } from '../../configurator/configurator-settings';
import { getIdByByPrice } from '../../configurator/utils/by-price.util';
import { ScrollService } from '../../core/scroll.service';
import { SettingsService } from '../../core/settings.service';
import { DialogService } from '../../utils/dialog/dialog.service';
import { navigateTab, setError, setModel, setStep, setTab, setTabState, softReset } from './configurator.actions';
import { ConfiguratorFacade } from './configurator.facade';

@Injectable()
export class ConfiguratorEffects implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly actions$: Actions,
        private readonly configuratorFacade: ConfiguratorFacade,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly scrollService: ScrollService,
        private readonly dialogService: DialogService,
        private readonly settingsService: SettingsService
    ) {
        // Listen for variant change, to validate the state.
        // Filter on model, so we only do it when a model is selected.
        this.configuratorFacade.variant$
            .pipe(
                concatLatestFrom(() => [this.configuratorFacade.model$, this.configuratorFacade.routerQueryParams$]),
                filter(([, model]) => Boolean(model)),
                map(([variant, , queryParams]) => ({ variant, queryParams })),
                takeUntil(this.unsubscribe)
            )
            .subscribe(({ variant, queryParams }) => {
                if (queryParams.finalized) {
                    return;
                }
                // If variant becomes undefined (mismatch in selections), set initial step selections.
                if (!variant) {
                    this.validateStates(false, ['bodystyle', 'enginetype', 'capacity', 'trim', 'powertrain', 'exterior', 'interior']);
                } else {
                    // Validate all other states that rely on variant
                    const paramKeys = Object.keys(queryParams);
                    const stepsToValidate: ConfiguratorTab[] = ['partner_products', 'accessories', 'optionals'];
                    const paramStepsToValidate: ConfiguratorTab[] = stepsToValidate.filter((tab) => paramKeys.includes(tab));
                    this.validateStates(false, paramStepsToValidate);
                }
            });
    }

    // The first effect. Happens once a model from elastic is found
    public onSetModel$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setModel),
                concatLatestFrom(() => [this.configuratorFacade.routerQueryParams$]),
                map(([{ model }, queryParams]) => {
                    if (queryParams.finalized) {
                        this.configuratorFacade.dispatch(setStep({ step: 'summary' }));
                        return;
                    }

                    // Set initial steps.
                    // After variant exists, the variant$ subscription handles further validation.
                    this.validateStates(false, ['bodystyle', 'enginetype', 'capacity', 'trim', 'powertrain', 'exterior', 'interior'], () => {
                        // Finally set active step based on data or query param.
                        const urlObj = new URL(window.location.href);
                        const csParam = urlObj.searchParams.get('cs') as ConfiguratorTab;

                        let initialTab: ConfiguratorTab = 'trim';
                        if (csParam) {
                            initialTab = csParam;
                        } else if ((model.bodyStyles?.length ?? 0) > 1) {
                            initialTab = 'bodystyle';
                        } else if ((model.bodyStyles?.[0].engineTypes?.length ?? 0) > 1) {
                            initialTab = 'enginetype';
                        } else if ((model.bodyStyles?.[0].engineTypes?.[0].capacities?.length ?? 0) > 1) {
                            initialTab = 'capacity';
                        }

                        this.configuratorFacade.dispatch(setTab({ tab: initialTab, isInitial: true }));
                    });
                })
            ),
        { dispatch: false }
    );

    public onSetTab$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setTab),
                map(({ tab, isInitial }) => {
                    this.router.navigate([], {
                        queryParams: { cs: tab },
                        queryParamsHandling: 'merge', // Preserves existing query params
                    });
                    if (!isInitial) {
                        this.handleStickyScroll();
                        this.validateState(tab);
                    }
                })
            ),
        { dispatch: false }
    );

    public onNavigateTab$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(navigateTab),
                concatLatestFrom(() => [this.configuratorFacade.tab$, this.configuratorFacade.step$, this.configuratorFacade.routerQueryParams$]),
                map(([, tab, step, queryParams]) => {
                    if (step === 'configuration') {
                        this.handleStickyScroll();
                        this.validateState(tab);
                        return;
                    }
                    // Validate params
                    if (step === 'summary') {
                        this.validateStates(Boolean(queryParams.finalized));
                    }
                    window.requestAnimationFrame(() => {
                        this.scrollService.scrollToPosition({ top: 0, behavior: 'auto' });
                    });
                })
            ),
        { dispatch: false }
    );

    public onSetStep$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setStep),
                concatLatestFrom(() => [this.configuratorFacade.routerQueryParams$]),
                map(([{ step }, queryParams]) => {
                    // Validate params
                    if (step === 'summary') {
                        this.validateStates(Boolean(queryParams.finalized));
                    }
                    window.requestAnimationFrame(() => {
                        this.scrollService.scrollToPosition({ top: 0, behavior: 'auto' });
                    });
                })
            ),
        { dispatch: false }
    );

    public onSoftReset$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(softReset),
                concatLatestFrom(() => [this.configuratorFacade.model$]),
                map(([, model]) => {
                    const firstBodyStyle = model?.bodyStyles?.[0];
                    const firstEngineType = firstBodyStyle?.engineTypes?.[0];
                    const firstCapacity = firstEngineType?.capacities?.[0];
                    const firstTrim = firstCapacity?.trims?.[0];
                    this.addRouterParam(
                        {
                            bodystyle: firstBodyStyle?.id || undefined,
                            enginetype: firstEngineType?.id || undefined,
                            capacity: firstCapacity?.id || undefined,
                            trim: firstTrim?.id || undefined,
                            powertrain: firstTrim?.variants?.[0].powerTrainId || undefined,
                            exterior: firstTrim?.variants?.[0].commercialColourOptionExteriorIds?.[0],
                        },
                        ''
                    );
                })
            ),
        { dispatch: false }
    );

    public onError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setError),
                distinctUntilChanged((prev, curr) => stringify(prev) === stringify(curr)),
                map(({ error }) => {
                    const type = error?.type;
                    const message = error?.message;
                    if (type) {
                        console.error('Type:', type, 'Message:', message);
                    }
                    switch (type) {
                        case 'incomplete-data': {
                            this.dialogService
                                .openDialog({
                                    data: {
                                        text: 'configurator.general_error',
                                        textIsSafe: false,
                                        disableCloseBtn: true,
                                    },
                                })
                                .then((ref) => {
                                    combineLatest([this.configuratorFacade.url$, ref.afterClose()])
                                        .pipe(take(1), takeUntil(this.unsubscribe))
                                        .subscribe(([url]) => {
                                            const tree = this.router.parseUrl(url);
                                            const segmentGroup = tree.root.children[PRIMARY_OUTLET];
                                            const segments = segmentGroup.segments;
                                            const parentSegment = segments[segments.length - 2];
                                            // Navigate back to the overview of models.
                                            // ActivatedPath just shows root, so we get path from store.
                                            this.router.navigate([`/${parentSegment.path}`], { relativeTo: this.route });
                                            this.configuratorFacade.dispatch(setError({}));
                                        });
                                })
                                .catch(console.error);
                            break;
                        }
                        case 'configuration-not-available': {
                            this.dialogService
                                .openDialog({
                                    data: {
                                        text: 'configurator.error_unavailable_config',
                                        textIsSafe: false,
                                        disableCloseBtn: true,
                                    },
                                })
                                .then((ref) => {
                                    ref.afterClose()
                                        .pipe(take(1), takeUntil(this.unsubscribe))
                                        .subscribe(() => {
                                            this.configuratorFacade.dispatch(setError({}));
                                        });
                                })
                                .catch(console.error);
                            break;
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    /**
     * If tabs array is not passed it will validate all tabs.
     */
    private validateStates(showConfigError: boolean, tabs?: ConfiguratorTab[], callback?: () => void) {
        const validateTabs = tabs ?? configuratorParamsWhiteList;
        validateTabs.forEach((param, index) => {
            // Use RAF to add sequentially to the event loop. URL change might experience race conditions if not.
            window.requestAnimationFrame(() => {
                // Remove finalized from params
                if (param === 'finalized') {
                    this.addRouterParam({ finalized: undefined });
                } else {
                    this.validateState(param, showConfigError);
                }

                // If this is the last iteration, call the callback
                if (index === validateTabs.length - 1 && callback) {
                    window.requestAnimationFrame(() => {
                        callback();
                    });
                }
            });
        });
    }

    private validateState(tab: ConfiguratorTab, showConfigError: boolean = false): void {
        combineLatest([
            this.configuratorFacade.routerQueryParams$,
            this.configuratorFacade.model$,
            this.configuratorFacade.bodyStyle$,
            this.configuratorFacade.engineType$,
            this.configuratorFacade.capacity$,
            this.configuratorFacade.trim$,
            this.configuratorFacade.variant$,
            this.configuratorFacade.tabStates$,
            this.settingsService.get().pipe(map((s) => s?.currency ?? 'DKK')),
        ])
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(
                ([params, model, selectedBodyStyle, selectedEngineType, selectedCapacity, selectedTrim, selectedVariant, tabStates, currency]) => {
                    const informOfTabChange = () => {
                        if (tabStates[tab] === 'visited') {
                            this.configuratorFacade.dispatch(setTabState({ tab, tabState: 'affected' }));
                        }
                    };
                    const informOfChangesToConfig = (param: string) => {
                        if (showConfigError) {
                            this.configuratorFacade.dispatch(
                                setError({ error: { type: 'configuration-not-available', message: 'Could not resolve param: ' + param } })
                            );
                        }
                    };
                    const informOfIncompleteData = (message: string) => {
                        this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data', message } }));
                    };

                    switch (tab) {
                        case 'bodystyle': {
                            const firstBodyStyleIdInModel = model?.bodyStyles?.[0]?.id;
                            if (!firstBodyStyleIdInModel) {
                                informOfIncompleteData('validate bodystyle failed');
                                break;
                            }
                            const validBodyStyle = model?.bodyStyles?.find((bodyStyle) => bodyStyle.id === params.bodystyle);
                            if (!validBodyStyle) {
                                this.addRouterParam({ bodystyle: firstBodyStyleIdInModel });
                                informOfChangesToConfig('bodystyle');
                            }
                            break;
                        }
                        case 'enginetype': {
                            const firstEngineTypeIdInBodyStyle = selectedBodyStyle?.engineTypes?.[0]?.id;
                            if (!firstEngineTypeIdInBodyStyle) {
                                informOfIncompleteData('validate enginetype failed');
                                break;
                            }
                            const validEngineType = selectedBodyStyle?.engineTypes?.find((engineType) => engineType.id === params.enginetype);
                            if (!validEngineType) {
                                this.addRouterParam({ enginetype: firstEngineTypeIdInBodyStyle });
                                informOfChangesToConfig('enginetype');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'capacity': {
                            const firstCapacityIdInEngineType = selectedEngineType?.capacities?.[0]?.id;
                            if (!firstCapacityIdInEngineType) {
                                informOfIncompleteData('validate capacity failed');
                                break;
                            }
                            const validCapacity = selectedEngineType?.capacities?.find((capacity) => capacity.id === params.capacity);
                            if (!validCapacity) {
                                this.addRouterParam({ capacity: firstCapacityIdInEngineType });
                                informOfChangesToConfig('capacity');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'trim': {
                            // To enable a non-step navigation, we must always combine trim and powertrain to get a variant.
                            const firstTrimIdInCapacity = selectedCapacity?.trims?.[0]?.id;
                            if (!firstTrimIdInCapacity) {
                                this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data', message: 'validate trim failed' } }));
                                break;
                            }
                            const validTrim = selectedCapacity?.trims?.find((trim) => trim.id === params.trim);
                            if (!validTrim) {
                                this.addRouterParam({ trim: firstTrimIdInCapacity });
                                informOfChangesToConfig('trim');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'powertrain': {
                            const firstPowerTrainIdInTrim = selectedTrim?.variants?.[0].powerTrainId;
                            if (!model?.powerTrains || !firstPowerTrainIdInTrim) {
                                informOfIncompleteData('validate powertrain failed');
                                break;
                            }
                            const validPowerTrain = selectedTrim?.variants?.find(({ powerTrainId }) => powerTrainId === params.powertrain);
                            if (!validPowerTrain) {
                                this.addRouterParam({ powertrain: firstPowerTrainIdInTrim });
                                informOfChangesToConfig('powertrain');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'exterior': {
                            const cheapestExteriorIdInVariant = getIdByByPrice(
                                selectedVariant?.commercialColourOptionExteriorIds,
                                model?.commercialColourOptionsExterior,
                                `CommercialColourOptionRetailSellingPrice`,
                                currency,
                                'lowest'
                            );

                            if (!model?.commercialColourOptionsExterior || !cheapestExteriorIdInVariant) {
                                informOfIncompleteData('validate exterior failed');
                                break;
                            }

                            const validExterior = selectedVariant?.commercialColourOptionExteriorIds?.find((id) => id === params.exterior);
                            if (!validExterior) {
                                this.addRouterParam({ exterior: cheapestExteriorIdInVariant });
                                informOfChangesToConfig('exterior');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'interior': {
                            const cheapestInteriorIdInVariant = getIdByByPrice(
                                selectedVariant?.commercialColourOptionInteriorIds,
                                model?.commercialColourOptionsInterior,
                                `CommercialColourOptionRetailSellingPrice`,
                                currency,
                                'lowest'
                            );

                            if (!model?.commercialColourOptionsInterior || !cheapestInteriorIdInVariant) {
                                informOfIncompleteData('validate interior failed');
                                break;
                            }

                            const validInterior = selectedVariant?.commercialColourOptionInteriorIds?.find((id) => id === params.interior);
                            if (!validInterior) {
                                this.addRouterParam({ interior: cheapestInteriorIdInVariant });
                                informOfChangesToConfig('interior');
                                informOfTabChange();
                            }
                            break;
                        }
                        case 'optionals': {
                            if (!params.optionals) {
                                break;
                            }
                            const optionArray = params.optionals.split(multiParamSeparator).filter((x) => x);
                            const validOptions = selectedVariant?.optionalOptionIds?.filter((id) => optionArray.includes(id)) ?? [];
                            if (validOptions.length < optionArray.length) {
                                this.addRouterParam({ optionals: validOptions.join(multiParamSeparator) || undefined });
                                informOfTabChange();
                                informOfChangesToConfig('optionals');
                            }
                            break;
                        }
                        case 'accessories': {
                            if (!params.accessories) {
                                break;
                            }
                            const accessoryArray = params.accessories.split(multiParamSeparator).filter((x) => x);
                            const ids = selectedVariant?.accessoryIds ?? selectedTrim?.accessoryIds ?? model?.accessoryIds;
                            const validAccessories = ids?.filter((id) => accessoryArray.includes(id)) ?? [];
                            if (validAccessories.length < accessoryArray.length) {
                                this.addRouterParam({ accessories: validAccessories.join(multiParamSeparator) || undefined });
                                informOfTabChange();
                                informOfChangesToConfig('accessories');
                            }
                            break;
                        }
                        case 'partner_products': {
                            if (!params.partner_products) {
                                break;
                            }
                            const partnerProductArray = params.partner_products.split(multiParamSeparator).filter((x) => x);
                            const validPartnerProducts = selectedTrim?.partnerProductIds?.filter((id) => partnerProductArray.includes(id)) ?? [];
                            if (validPartnerProducts.length < partnerProductArray.length) {
                                this.addRouterParam({ partner_products: validPartnerProducts.join(multiParamSeparator) || undefined });
                                informOfTabChange();
                                informOfChangesToConfig('partner_products');
                            }
                            break;
                        }
                    }
                }
            );
    }

    // If the user has scrolled below the navigation bar, then restore scroll to that point.
    // The offset is given by the 360 viewer
    private handleStickyScroll(): void {
        window.requestAnimationFrame(() => {
            const imageHeight = document.querySelector<HTMLElement>('ncg-configurator-360')?.offsetHeight;
            const currentScroll = this.scrollService.scrollPosition;
            if (imageHeight && currentScroll > imageHeight) {
                this.scrollService.scrollToPosition({ top: imageHeight, behavior: 'auto' });
            }
        });
    }

    private addRouterParam(params: ConfiguratorQueryParams, paramHandling: QueryParamsHandling = 'merge'): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: paramHandling,
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
