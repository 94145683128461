import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { SpinnerModule } from '../../../spinner/spinner.module';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../configurator-image/configurator-image.component';
import { fieldById } from '../../utils';
import { SettingsService } from '../../../core/settings.service';
import { configuratorImageAspectRatio } from '../../configurator-settings';
import { TranslateModule } from '@ngx-translate/core';
import { getBuildIdQuery } from '@ncg/data';

// TODO: Consider adding proper types, just snatched it from model-viewer-exterior.component.ts
declare let ThreeSixty: any;

@Component({
    selector: 'ncg-configurator-360',
    template: `
        <div
            class="viewer"
            [ngClass]="{ faded: state === 'loading', grabbable: state === 'idle', selected: ['idle', 'static'].includes(state) }"
            #viewer
        >
            <p *ngIf="state === 'error'">{{ 'configurator.360_error' | translate }}</p>
            <div *ngIf="(configuratorFacade.step$ | async) === 'summary' && state === 'idle'" class="overlay" aria-hidden="true">
                <img [src]="'/assets/images/360-overlay.png' + buildIdQuery" alt="overlay image" />
                <span class="text configurator__sub-headline">{{ 'model_viewer_spot.overlay_title' | translate }}</span>
            </div>
        </div>
        <ncg-spinner *ngIf="state === 'loading'" [active]="true" [isCentered]="true" [isSmall]="true"></ncg-spinner>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./configurator-360.component.scss'],
    imports: [CommonModule, UtilsModule, TranslateModule, SpinnerModule, ConfiguratorImageComponent],
})
export class Configurator360Component implements OnInit, OnDestroy {
    @ViewChild('viewer', { static: true }) viewer: ElementRef<HTMLDivElement>;
    private readonly unsubscribe = new Subject<void>();
    private threeSixty: any;

    public buildIdQuery = getBuildIdQuery();
    public state: 'loading' | 'error' | 'idle' | 'static' = 'loading';

    // Make sure we avoid CLS by having aspect ratio on host
    @HostBinding('style.aspectRatio') get aspectRatio() {
        return `1 / ${configuratorImageAspectRatio.car}`;
    }

    constructor(
        private readonly settingsService: SettingsService,
        private readonly cd: ChangeDetectorRef,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    ngOnInit(): void {
        combineLatest([this.settingsService.settings$, this.configuratorFacade.exterior$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([settings, exterior]) => {
                if (!exterior) {
                    // Wait for exterior to exist before doing anything
                    return;
                }
                this.state = 'loading';
                this.cd.markForCheck();
                if (!exterior.resource360) {
                    this.state = 'error';
                    this.threeSixty?.destroy();
                    this.cd.markForCheck();
                    return;
                }
                // Sort image urls by the ResourcePerspective key.
                const imageUrls =
                    [...exterior.resource360]
                        .sort((a, b) => {
                            const aKey = fieldById(a.fields, 'ResourcePerspective')?.data?.key || '';
                            const bKey = fieldById(b.fields, 'ResourcePerspective')?.data?.key || '';
                            return aKey > bKey ? 1 : -1;
                        })
                        .map(({ fields }) => `${settings.mediaUrlNCG}/${fieldById(fields, 'ResourceCdnUrlOriginal')?.data?.value}`)
                        .filter((x) => x) || [];

                const element = this.viewer?.nativeElement;

                if (!(element && imageUrls)) {
                    this.state = 'error';
                    this.threeSixty?.destroy();
                    this.cd.markForCheck();
                    return;
                }

                // Load all images before showing 360
                Promise.allSettled(
                    imageUrls.map((url) => {
                        const img = new Image();
                        img.src = url;
                        return img.decode();
                    })
                )
                    .then(() => {
                        this.threeSixty?.destroy();
                        this.threeSixty = new ThreeSixty(element, {
                            image: imageUrls,
                            aspectRatio: configuratorImageAspectRatio.car,
                            inverted: true,
                            drawTolerance: 15,
                            swipeTolerance: 15,
                        });
                        this.state = imageUrls.length > 1 ? 'idle' : 'static';
                    })
                    .catch(() => {
                        this.state = 'error';
                    })
                    .finally(() => {
                        this.cd.markForCheck();
                    });
            });
    }

    ngOnDestroy(): void {
        this.threeSixty?.destroy();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
