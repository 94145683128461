import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarModelV2 } from '@ncg/data';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfiguratorService {
    constructor(private readonly httpClient: HttpClient) {}

    public getConfiguratorModelByNumber(number: number): Observable<CarModelV2> {
        return this.httpClient.get<CarModelV2>(`/api/configurator/number/${number}`);
    }

    public getConfiguratorModelById(id: string): Observable<CarModelV2> {
        return this.httpClient.get<CarModelV2>(`/api/configurator/id/${id}`);
    }

    public getConfiguratorModelByName(name: string): Observable<CarModelV2> {
        return this.httpClient.get<CarModelV2>(`/api/configurator/name/${name}`);
    }
}
