import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CapacityViewModel, CarModelV2, IOfferCarViewModel, ModelsListItemSimple, NcgProductCatalog, PageResponse, TrimViewModel } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';

import { ITestDriveUsedModel } from '../form/test-drive-form.component';
import { ImpactOverlayRef } from '../overlay/overlay-ref';
import { DialogService } from '../utils/dialog/dialog.service';

@Injectable({
    providedIn: 'root',
})
export class SidePanelService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly isSidePanel$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        private readonly http: HttpClient,
        private readonly trackingService: TrackingService,
        private readonly settingsService: SettingsService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    openContent(url: string, customComponent?: string) {
        this.settingsService
            .getCulture()
            .pipe(
                switchMap((culture) => this.http.get<PageResponse>('/api/page/url', { params: { url, culture } })),
                takeUntil(this.unsubscribe)
            )
            .subscribe((page) => {
                if (page.template !== 'sidepanel') {
                    console.warn(`SidePanelService: Tried to open side panel, but pointed to: "${page.template}" from "${url}" instead.`);
                    return;
                }
                const component = customComponent ?? 'sidepanel';
                this.openSidePanel(component, page).catch(() => console.error(`SidePanelService: Failed to open side panel from "${url}".`));
            });
    }

    async openSidePanel(component: string, componentData: any): Promise<ImpactOverlayRef> {
        const { SidePanelComponent } = await import('./side-panel.component');
        const ref = this.dialogService.openDialogWithComponent(SidePanelComponent, {
            fullHeight: true,
            blockScroll: true,
            hasBackdrop: true,
            data: {
                component,
                componentData,
            },
            positionHorizontal: {
                placement: 'right',
            },
        });
        ref.afterClose()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.trackingService.actionBarConversionInProgress = undefined;
            });
        return ref;
    }

    openTestDrive(config: {
        collectMarketingConsent?: boolean;
        usedCar?: ITestDriveUsedModel;
        isModelPage?: boolean;
        models?: ModelsListItemSimple[];
        successMessage?: string;
        locationWhitelist?: string[];
        locationBlacklist?: string[];
    }): Promise<ImpactOverlayRef> {
        if (!config.successMessage) {
            config.successMessage = this.translateService.instant('sidepanel.test_drive_success_text');
        }
        return this.openSidePanel('test-drive', config);
    }

    openOffer(model: IOfferCarViewModel): Promise<ImpactOverlayRef> {
        return this.openSidePanel('offer', { model });
    }

    openTrimsEquipmentPanel(model: CarModelV2, capacity: CapacityViewModel, selectedTrimId: TrimViewModel['id']): void {
        this.openSidePanel('trims-equipment', {
            model,
            capacity,
            selectedTrimId,
        });
    }

    openServiceBookingService(service: NcgProductCatalog): Promise<ImpactOverlayRef> {
        return this.openSidePanel('service-booking-service', service);
    }

    isSidePanel() {
        return this.isSidePanel$.asObservable();
    }

    toggleIsSidePanel(newValue?: boolean): void {
        if (newValue) {
            this.isSidePanel$.next(newValue);
        } else {
            this.isSidePanel$.next(!this.isSidePanel$.value);
        }
    }
}
