import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    ConfiguratorAccessoriesComponent,
    ConfiguratorBodyStyleComponent,
    ConfiguratorCapacityComponent,
    ConfiguratorEngineTypeComponent,
    ConfiguratorExteriorComponent,
    ConfiguratorInteriorComponent,
    ConfiguratorOptionalsComponent,
    ConfiguratorPartnerProductsComponent,
    ConfiguratorPowertrainComponent,
    ConfiguratorTrimsComponent,
} from '../..';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { ConfiguratorTabsContentComponent } from './configurator-tabs-content/configurator-tabs-content.component';

@Component({
    selector: 'ncg-configurator-tabs-container',
    template: `
        <ng-container *ngIf="configuratorFacade as state">
            <div class="tabs-container" [ngSwitch]="state.tab$ | async">
                <ncg-configurator-tabs-content *ngSwitchCase="'bodystyle'">
                    <ncg-configurator-body-style></ncg-configurator-body-style>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'enginetype'">
                    <ncg-configurator-engine-type></ncg-configurator-engine-type>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'capacity'">
                    <ncg-configurator-capacity></ncg-configurator-capacity>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'trim'">
                    <ncg-configurator-trims></ncg-configurator-trims>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'powertrain'">
                    <ncg-configurator-powertrain></ncg-configurator-powertrain>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'exterior'">
                    <ncg-configurator-exterior></ncg-configurator-exterior>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'interior'">
                    <ncg-configurator-interior></ncg-configurator-interior>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'optionals'">
                    <ncg-configurator-optionals></ncg-configurator-optionals>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'accessories'">
                    <ncg-configurator-accessories></ncg-configurator-accessories>
                </ncg-configurator-tabs-content>
                <ncg-configurator-tabs-content *ngSwitchCase="'partner_products'">
                    <ncg-configurator-partner-products></ncg-configurator-partner-products>
                </ncg-configurator-tabs-content>
            </div>
        </ng-container>
    `,
    styles: [
        `
            .tabs-container {
                display: grid;
                grid-template-columns: minmax(0, 1fr);
            }
        `,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslateModule,
        ConfiguratorTabsContentComponent,
        ConfiguratorBodyStyleComponent,
        ConfiguratorCapacityComponent,
        ConfiguratorEngineTypeComponent,
        ConfiguratorTrimsComponent,
        ConfiguratorPowertrainComponent,
        ConfiguratorExteriorComponent,
        ConfiguratorInteriorComponent,
        ConfiguratorOptionalsComponent,
        ConfiguratorPartnerProductsComponent,
        ConfiguratorAccessoriesComponent,
    ],
})
export class ConfiguratorTabsContainerComponent {
    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}
}
