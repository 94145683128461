import { formatWithCurrency as currencyFormatter } from '@ncg/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfiguratorState, ConfiguratorStep, ConfiguratorStore, ConfiguratorTab } from '../../configurator/configurator';
import { CONFIGURATOR_FEATURE_KEY, multiParamSeparator } from '../../configurator/configurator-settings';
import { fieldById } from '../../configurator/utils/field-by-id.util';

export interface Price {
    price?: number;
    priceFormatted?: string;
}

export interface PriceSelector extends Price {
    variant: Price;
    exterior: Price;
    interior: Price;
    optionals: Price;
    partnerProducts: Price;
    total: Price;
    accessories: Price;
    showPrice: boolean;
}

export const getConfiguratorStore = createFeatureSelector<ConfiguratorStore>(CONFIGURATOR_FEATURE_KEY);

export const configurator = createSelector(getConfiguratorStore, (store: ConfiguratorStore) => store.configurator);
export const url = createSelector(getConfiguratorStore, ({ router }: ConfiguratorStore) => router?.state?.url ?? {});

export const queryParams = createSelector(getConfiguratorStore, ({ router }: ConfiguratorStore) => router?.state?.queryParams ?? {});
export const step = createSelector(configurator, (c) => c.step);
export const tab = createSelector(configurator, (c) => c.tab);
export const enabledTabs = createSelector(configurator, (c) => c.enabledTabs);
export const tabDirection = createSelector(configurator, (c) => c.tabDirection);
export const pageData = createSelector(configurator, (c) => c.pageData);
export const categories = createSelector(configurator, (c) => c.categories);
export const model = createSelector(configurator, (c) => c.model);

export const bodystyle = createSelector(model, queryParams, (m, params) => m?.bodyStyles?.find((b) => b.id === params.bodystyle));
export const enginetype = createSelector(bodystyle, queryParams, (b, params) => b?.engineTypes?.find((e) => e.id === params.enginetype));
export const capacity = createSelector(enginetype, queryParams, (e, params) => e?.capacities?.find((c) => c.id === params.capacity));
export const trim = createSelector(capacity, queryParams, (c, params) => c?.trims?.find((t) => t.id === params.trim));
export const variant = createSelector(queryParams, trim, (params, selectedTrim) => {
    const { powertrain: powertrainParam } = params;
    return selectedTrim?.variants?.find((p) => p.powerTrainId === powertrainParam);
});
export const showPrices = createSelector(queryParams, variant, (params, selectedVariant) =>
    Boolean(fieldById(selectedVariant?.fields, 'VariantShowPricesOnPublicWeb')?.data?.value)
);
export const powertrain = createSelector(queryParams, model, (params, m) => {
    const { powertrain: powertrainParam = '' } = params;
    return m?.powerTrains?.[powertrainParam];
});
export const exterior = createSelector(queryParams, model, (params, m) => {
    const { exterior: exteriorParam = '' } = params;
    return m?.commercialColourOptionsExterior?.[exteriorParam];
});
export const interior = createSelector(queryParams, model, (params, m) => {
    const { interior: interiorParam = '' } = params;
    return m?.commercialColourOptionsInterior?.[interiorParam];
});
export const optionals = createSelector(queryParams, model, (params, m) => {
    const { optionals: optionalsParam = '' } = params;
    const ids = optionalsParam.split(multiParamSeparator).filter((x) => x);
    return ids.map((id) => m?.optionalOptions?.[id]);
});
export const partnerProducts = createSelector(queryParams, model, (params, m) => {
    const { partner_products: partnerProductsParam = '' } = params;
    const ids = partnerProductsParam.split(multiParamSeparator).filter((x) => x);
    return ids.map((id) => m?.partnerProducts?.[id]);
});
export const accessories = createSelector(getConfiguratorStore, model, ({ router }, m) => {
    const { accessories: accessoriesParam = '' } = router.state.queryParams;
    const ids = accessoriesParam.split(multiParamSeparator).filter((x) => x);
    return ids.map((id) => m?.accessories?.[id]);
});
export const currency = createSelector(configurator, (c) => c.currency);
export const price = createSelector(
    currency,
    variant,
    exterior,
    interior,
    optionals,
    partnerProducts,
    accessories,
    (
        selectedCurrency,
        selectedVariant,
        selectedExterior,
        selectedInterior,
        selectedOptionals,
        selectedPartnerProducts,
        selectedAccessories
    ): PriceSelector => {
        const cur = selectedCurrency;
        const showPrice = Boolean(fieldById(selectedVariant?.fields, 'VariantShowPricesOnPublicWeb')?.data?.value);
        const variantPrice = Number(fieldById(selectedVariant?.fields, `VariantRetailSellingPrice${cur}`)?.data?.value) || 0;
        const exteriorPrice = Number(fieldById(selectedExterior?.fields, `CommercialColourOptionRetailSellingPrice${cur}`)?.data?.value) || 0;
        const interiorPrice = Number(fieldById(selectedInterior?.fields, `CommercialColourOptionRetailSellingPrice${cur}`)?.data?.value) || 0;

        const optionalsPrice = selectedOptionals
            .map(
                (option) =>
                    fieldById(option?.fields, `OptionPriceRetailSellingPrice${cur}`)?.data?.reduce(
                        (acc, { value = 0 }) => Number(acc) + Number(value),
                        0
                    ) || 0
            )
            ?.reduce((total, optionPrice) => Number(total) + Number(optionPrice), 0);
        const partnerProductsPrice = selectedPartnerProducts.reduce(
            (total, product) => Number(total) + (Number(fieldById(product?.fields, `PartnerProductRetailSellingPrice${cur}`)?.data?.value) || 0),
            0
        );
        const accessoriesPrice = selectedAccessories.reduce(
            (total, accessory) => Number(total) + (Number(fieldById(accessory?.fields, `AccessoryRetailSellingPrice${cur}`)?.data?.value) || 0),
            0
        );
        const totalPrice = [variantPrice, exteriorPrice, interiorPrice, optionalsPrice, accessoriesPrice, partnerProductsPrice].reduce(
            (total, curr) => Number(total) + Number(curr),
            0
        );

        return {
            variant: {
                price: variantPrice,
                priceFormatted: currencyFormatter(variantPrice, cur),
            },
            exterior: {
                price: exteriorPrice,
                priceFormatted: currencyFormatter(exteriorPrice, cur),
            },
            interior: {
                price: interiorPrice,
                priceFormatted: currencyFormatter(interiorPrice, cur),
            },
            optionals: {
                price: optionalsPrice,
                priceFormatted: currencyFormatter(optionalsPrice, cur),
            },
            partnerProducts: {
                price: partnerProductsPrice,
                priceFormatted: currencyFormatter(partnerProductsPrice, cur),
            },
            accessories: {
                price: accessoriesPrice,
                priceFormatted: currencyFormatter(accessoriesPrice, cur),
            },
            total: {
                price: totalPrice,
                priceFormatted: currencyFormatter(totalPrice, cur),
            },
            showPrice,
        };
    }
);
export const error = createSelector(configurator, (c: ConfiguratorState) => c.error);
export const tabStates = createSelector(configurator, (c: ConfiguratorState) => c.tabStates);
export const trackingStep = createSelector(configurator, (c: ConfiguratorState) => {
    // Because steps aren't fixed (e.g. BYD does not have optionals), a dynamic index is created based on which tabs are enabled
    const stepsToBeTracked: (ConfiguratorTab | Partial<ConfiguratorStep>)[] = [...c.enabledTabs, 'summary', 'dealer', 'mail'];
    const currentStep = c.step === 'configuration' ? c.tab : c.step;
    return { number: stepsToBeTracked.indexOf(currentStep) + 1, step: currentStep };
});
