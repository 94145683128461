import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModelPriceTableRow } from '@ncg/data';

@Component({
    selector: 'ncg-model-prices-spot-table',
    template: `
        <div class="model-prices-spot" *ngIf="models && models.length">
            <div class="model-prices-spot__container">
                <h3
                    *ngIf="!this.hideFuelTypeHeader"
                    class="model-prices-spot-header model-prices-spot__fuel-type-header is-hidden-touch"
                    [innerHTML]="currentFuelValue"
                ></h3>
                <table class="table model-prices-spot__table">
                    <thead class="has-text-left">
                        <tr *ngIf="!this.hideFuelTypeHeader" class="is-hidden-desktop model-prices-spot__mobile-header">
                            <th [attr.colspan]="headerTitles.length" class="model-prices-spot-header" [innerHTML]="currentFuelValue"></th>
                        </tr>
                        <tr class="is-hidden-vertical-table">
                            <ng-container *ngFor="let title of headerTitles">
                                <ng-container *ngIf="title !== 'co2'; else co2">
                                    <th class="table-row-{{ title }}">{{ 'model_prices.table_header_' + title | translate }}</th>
                                </ng-container>
                                <ng-template #co2>
                                    <th class="table-row-co2">CO<sub class="lowered-number">2</sub></th>
                                </ng-template>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="isCampaign; else isNotCampaign">
                            <ng-container *ngFor="let model of models">
                                <tr *ngIf="checkForTableGap(model, models)">
                                    <td class="empty-cell"></td>
                                </tr>
                                <tr>
                                    <ng-container *ngIf="model.priceDiscount && model.fuelTypeCode === currentFuelType">
                                        <td
                                            *ngIf="tableCellColumns.variant"
                                            [attr.data-title]="'model_prices.table_header_variant' | translate"
                                            class="table-row-variant"
                                        >
                                            <span>
                                                {{ model.variant }}
                                                <ncg-info-label [isCampaign]="model.priceDiscount ? true : false"></ncg-info-label>
                                            </span>
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.model"
                                            [attr.data-title]="'model_prices.table_header_model' | translate"
                                            class="table-row-model"
                                        >
                                            {{ model.model }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.gear"
                                            [attr.data-title]="'model_prices.table_header_gear' | translate"
                                            class="no-wrap table-row-gear"
                                        >
                                            {{ model.transmission }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.usage"
                                            [attr.data-title]="'model_prices.table_header_usage' | translate"
                                            class="table-row-usage"
                                        >
                                            {{ model.fuelEfficiency }}
                                        </td>
                                        <td *ngIf="tableCellColumns.co2" [attr.data-title]="'CO&#8322;'" class="table-row-co2">{{ model.co2 }}</td>
                                        <td
                                            *ngIf="tableCellColumns.ncap"
                                            [attr.data-title]="'model_prices.table_header_ncap' | translate"
                                            class="table-row-ncap"
                                        >
                                            <ncg-ncap-stars [stars]="model.NCAP" [isSmall]="true"></ncg-ncap-stars>
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.energy_label"
                                            [attr.data-title]="'model_prices.table_header_energy_label' | translate"
                                            class="table-row-energy_label"
                                        >
                                            <ncg-energy-label [energyLabels]="model.energyLabels" imageClassModifier="energy-image--large">
                                            </ncg-energy-label>
                                        </td>

                                        <td
                                            *ngIf="tableCellColumns.yearly_tax"
                                            [attr.data-title]="'model_prices.table_header_yearly_tax' | translate"
                                            class="table-row-yearly_tax"
                                        >
                                            {{ model.priceYearly }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.price"
                                            [attr.data-title]="'model_prices.table_header_price' | translate"
                                            [ngClass]="{ 'discount-price': model.priceDiscount && model.priceTotal }"
                                            class="table-row-price"
                                        >
                                            <span>
                                                <span *ngIf="model.priceTotal" class="line-through">{{ model.priceTotal }}</span>
                                                <br *ngIf="model.priceTotal" />
                                                <span class="bold">{{ model.priceDiscount }}</span>
                                            </span>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-template #isNotCampaign>
                            <ng-container *ngFor="let model of models">
                                <tr *ngIf="checkForTableGap(model, models)">
                                    <td class="empty-cell"></td>
                                </tr>
                                <tr>
                                    <ng-container *ngIf="!model.priceDiscount && model.fuelTypeCode === currentFuelType">
                                        <td
                                            *ngIf="tableCellColumns.variant"
                                            [attr.data-title]="'model_prices.table_header_variant' | translate"
                                            class="table-row-variant"
                                        >
                                            {{ model.variant }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.model"
                                            [attr.data-title]="'model_prices.table_header_model' | translate"
                                            class="table-row-model"
                                        >
                                            {{ model.model }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.gear"
                                            [attr.data-title]="'model_prices.table_header_gear' | translate"
                                            class="no-wrap table-row-gear"
                                        >
                                            {{ model.transmission }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.usage"
                                            [attr.data-title]="'model_prices.table_header_usage' | translate"
                                            class="table-row-usage"
                                        >
                                            {{ model.fuelEfficiency }}
                                        </td>
                                        <td *ngIf="tableCellColumns.co2" [attr.data-title]="'CO&#8322;'" class="table-row-co2">{{ model.co2 }}</td>
                                        <td
                                            *ngIf="tableCellColumns.ncap"
                                            [attr.data-title]="'model_prices.table_header_ncap' | translate"
                                            class="table-row-ncap"
                                        >
                                            <ncg-ncap-stars [stars]="model.NCAP" [isSmall]="true"></ncg-ncap-stars>
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.energy_label"
                                            [attr.data-title]="'model_prices.table_header_energy_label' | translate"
                                            class="table-row-energy_label"
                                        >
                                            <ncg-energy-label [energyLabels]="model.energyLabels" imageClassModifier="energy-image--large">
                                            </ncg-energy-label>
                                        </td>

                                        <td
                                            *ngIf="tableCellColumns.yearly_tax"
                                            [attr.data-title]="'model_prices.table_header_yearly_tax' | translate"
                                            class="table-row-yearly_tax"
                                        >
                                            {{ model.priceYearly }}
                                        </td>
                                        <td
                                            *ngIf="tableCellColumns.price"
                                            [attr.data-title]="'model_prices.table_header_price' | translate"
                                            class="table-row-price"
                                        >
                                            <span>{{ model.priceTotal }}</span>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPricesSpotTableComponent implements OnInit {
    static ref = 'modelprices';

    @Input() hideFuelTypeHeader: boolean;
    @Input() currentFuelType: string;
    @Input() models: ModelPriceTableRow[];
    @Input() isCampaign: boolean;

    checkColumns: ModelPriceTableRow;
    tableCellColumns: Record<PriceSpotTableColumn, boolean> = {
        variant: false,
        model: false,
        gear: false,
        usage: false,
        co2: false,
        ncap: false,
        energy_label: false,
        yearly_tax: false,
        price: false,
    };
    headerTitles: PriceSpotTableColumn[] = [];

    currentFuelValue: string;

    ngOnInit() {
        this.sortModels();
        this.setTable();

        const matchingType = this.models.find((model: ModelPriceTableRow) => model.fuelTypeCode === this.currentFuelType);
        if (matchingType) {
            this.currentFuelValue = matchingType.fuelTypeValue;
        }
    }

    checkForTableGap(model: ModelPriceTableRow, models: ModelPriceTableRow[]): boolean {
        const currentModelArray = models.filter((item: ModelPriceTableRow) => item.fuelTypeCode === this.currentFuelType);

        if (model.fuelTypeCode === this.currentFuelType) {
            const modelIndex = currentModelArray.indexOf(model);
            const prevModel = currentModelArray[modelIndex - 1];
            if (prevModel) {
                if (parseFloat(model.enginePower) > parseFloat(prevModel.enginePower)) {
                    return true;
                } else if (model.is4WD && !prevModel.is4WD) {
                    return true;
                }
            }
        }
        return false;
    }

    private sortModels() {
        this.models = this.models.sort((a: ModelPriceTableRow, b: ModelPriceTableRow) => {
            const currentPriceA = a.priceDiscountUnformatted ? a.priceDiscountUnformatted : a.priceTotalUnformatted;
            const currentPriceB = b.priceDiscountUnformatted ? b.priceDiscountUnformatted : b.priceTotalUnformatted;
            const enginePowerA = parseFloat(a.enginePower);
            const enginePowerB = parseFloat(b.enginePower);

            if (currentPriceA && currentPriceB) {
                return Number(a.is4WD) - Number(b.is4WD) || enginePowerA - enginePowerB || currentPriceA - currentPriceB;
            }
            return -1;
        });
    }

    setTable() {
        this.models.forEach((model: ModelPriceTableRow) => {
            if (model.variant) {
                this.tableCellColumns.variant = true;
            }
            if (model.model) {
                this.tableCellColumns.model = true;
            }
            if (model.transmission) {
                this.tableCellColumns.gear = true;
            }
            if (model.fuelEfficiency) {
                this.tableCellColumns.usage = true;
            }
            if (model.co2) {
                this.tableCellColumns.co2 = true;
            }
            if (model.NCAP) {
                this.tableCellColumns.ncap = true;
            }
            if (model.energyLabels && model.energyLabels.length > 0) {
                this.tableCellColumns.energy_label = true;
            }
            if (model.priceYearly) {
                this.tableCellColumns.yearly_tax = true;
            }
            if (model.priceTotal || model.priceDiscount) {
                this.tableCellColumns.price = true;
            }
        });
        Object.entries(this.tableCellColumns).forEach(([key, shouldRender]) => {
            if (shouldRender) {
                this.headerTitles.push(key as PriceSpotTableColumn);
            }
        });
    }
}

type PriceSpotTableColumn = 'variant' | 'model' | 'gear' | 'usage' | 'co2' | 'ncap' | 'energy_label' | 'yearly_tax' | 'price';
