import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AppGtmCustomEvent, AppGtmDataLayer, AppWindow } from '@ncg/data';
import { sha256 } from 'js-sha256';
import { pick } from 'query-string';
import { BehaviorSubject, Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { Favorite } from '../favorites-service/favorites';
import { FeatureDetectionService } from './feature-detection.service';
import { ScriptLoadService } from './script-load.service';
import { APPINSIGHTS_TOKEN } from './tokens';

declare let CookieInformation: any;
const AI_STATE_KEY = makeStateKey<string>('APPINSIGHTS_INSTRUMENTATIONKEY');

@Injectable({
    providedIn: 'root',
})
export class TrackingService implements OnDestroy {
    private readonly defaultConsent = false; // Setting this to true will essentially disable the consent check (use for local test).
    private readonly unsubscribe = new Subject<void>();

    private readonly utm$ = new BehaviorSubject('');
    private readonly marketingCookieConsent$ = new BehaviorSubject(this.defaultConsent);
    private readonly statisticCookieConsent$ = new BehaviorSubject(this.defaultConsent);
    private readonly functionalCookieConsent$ = new BehaviorSubject(this.defaultConsent);
    private readonly appWindow: AppWindow;

    private appInsights: ApplicationInsights;

    public readonly isGtmLoaded$ = new BehaviorSubject(false);
    public readonly isCookieInformationLoaded$ = new BehaviorSubject(this.defaultConsent);
    public readonly isHjLoaded$ = new BehaviorSubject(false);
    public readonly isClarityLoaded$ = new BehaviorSubject(false);

    public actionBarConversionInProgress: 'test_drive' | 'newsletter' | 'trade_in_car' | undefined;

    constructor(
        private readonly router: Router,
        private readonly transferState: TransferState,
        private readonly featureDetection: FeatureDetectionService,
        private readonly scriptLoadService: ScriptLoadService,
        @Inject(DOCUMENT)
        private readonly document: Document,
        @Optional()
        @Inject(APPINSIGHTS_TOKEN)
        readonly _aiInstrumentationKey?: string
    ) {
        this.appWindow = (this.featureDetection.isBrowser() ? window : {}) as AppWindow;

        this.addCookieInformationEvent();

        const aiInstrumentationKey = this.transferState.get<string | undefined>(AI_STATE_KEY, _aiInstrumentationKey);

        if (aiInstrumentationKey) {
            transferState.set(AI_STATE_KEY, aiInstrumentationKey);
            this.initApplicationInsights(aiInstrumentationKey);
        } else {
            console.warn('APPINSIGHTS is missing instrumentation key');
        }

        this.initUtm();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * Only for testing on localhost.
     * Call inside a setTimeout to simulate user changing consent.
     */
    public testSetConsent(functional: boolean, statistic: boolean, marketing: boolean) {
        if (this.featureDetection.isServer() || !location.hostname.includes('localhost')) {
            throw new Error('Do not use "testSetConsent" outside of localhost!');
        }

        this.functionalCookieConsent$.next(functional);
        this.statisticCookieConsent$.next(statistic);
        this.marketingCookieConsent$.next(marketing);
    }

    public functionalConsentGiven(): Observable<boolean> {
        return this.functionalCookieConsent$.asObservable();
    }

    public marketingConsentGiven(): Observable<boolean> {
        return this.marketingCookieConsent$.asObservable();
    }

    public statisticConsentGiven(): Observable<boolean> {
        return this.statisticCookieConsent$.asObservable();
    }

    public utm() {
        return this.utm$.asObservable();
    }

    public getUtmValue() {
        return this.utm$.value;
    }

    public initGtm(gtmId: string): BehaviorSubject<boolean> {
        this._initGtm(gtmId);
        return this.isGtmLoaded$;
    }

    public initCookieInformation(lang: string): BehaviorSubject<boolean> {
        this._initCookieInformation(lang);
        return this.isCookieInformationLoaded$;
    }

    public initHotjar(hjId: number, hjSv = 6): BehaviorSubject<boolean> {
        this._initHotjar(hjId, hjSv);
        return this.isHjLoaded$;
    }

    public loadClarityScript(clarityId: string): BehaviorSubject<boolean> {
        this._initClarity(clarityId);
        return this.isClarityLoaded$;
    }

    public initClarityTracking(clearSession?: true): void {
        if (!clearSession) {
            this.appWindow.clarity('consent');
        } else {
            this.appWindow.clarity('consent', false);
        }
    }

    /**
     * Used to track page views. Both regular and dynamic.
     */
    public trackVirtualPageview() {
        this.push({
            event: 'virtualPageView',
            virtualPage: this.getHref(),
            virtualPageTitle: this.document.title,
        });
    }

    public reloadCookieInformation() {
        if (!this.featureDetection.isAudit() && window && 'CookieInformation' in window) {
            CookieInformation.loadConsent();
        }
    }

    /**
     * Track 404 pages.
     */
    public track404() {
        this.push({
            event: 'serverEvent',
            eventCategory: '404 not found',
            eventAction: this.getHref(),
            eventLabel: this.document.referrer,
        });
    }

    /**
     * Track form submissions
     */
    public trackFormSubmission(options: AppGtmCustomEvent & { email: string }) {
        const { eventCategory, eventAction, eventLabel = '-', email, ...rest } = options;
        this.push({
            event: 'serverEvent',
            eventCategory,
            eventAction,
            eventLabel,
            email: sha256(email),
            lead_menu: !!this.actionBarConversionInProgress,
            ...rest,
        });
    }

    /**
     * Track search
     */
    public trackSearch(eventLabel: string, eventAction: 'results' | 'no results' = 'no results') {
        this.push({
            event: 'serverEvent',
            eventCategory: 'search',
            eventAction,
            eventLabel,
        });
    }

    /**
     * Track newsletter/email signup.
     */
    public trackEmailSignup(
        email: string,
        eventLabel = 'permissions',
        eventAction: 'permissions' | 'permissions overlay' = 'permissions',
        newsletter: boolean = false
    ) {
        this.push({
            event: 'serverEvent',
            eventCategory: 'permissions',
            eventAction,
            eventLabel,
            newsletter,
            email: sha256(email),
            lead_menu: !!this.actionBarConversionInProgress,
        });
    }

    /**
     * Track unsubscribe newsletter/email.
     */
    public trackUnsubscribeEmail(eventCategory: string, eventAction: string, eventLabel = '-') {
        this.push({
            event: 'serverEvent',
            eventCategory,
            eventAction,
            eventLabel,
        });
    }

    /**
     * Track used car product page viewed
     */
    public trackProductPageViewed() {
        this.push({
            event: 'serverEvent',
            eventCategory: 'product page viewed',
            eventAction: 'used car product page',
            eventLabel: this.document.location.pathname,
        });
    }

    /**
     * Show CookieInformation cookie popup
     */
    public showCookieBanner() {
        if (!this.featureDetection.isAudit() && window && (window as any).hasOwnProperty('showCookieBanner')) {
            (window as any).showCookieBanner();
        }
    }

    /**
     * Track visible vehicle models
     *
     * @param pimIds Array of pimIds of visible models
     */
    public trackVehicle(pimIds: string[]) {
        if (!this.featureDetection.isAudit() && this.featureDetection.isBrowser() && this.appWindow.platform) {
            this.appWindow.platform.productIds.length = 0;
            this.appWindow.platform.productIds = this.appWindow.platform.productIds.concat(pimIds);
            const event = new CustomEvent<string[]>('productidschanged', { detail: pimIds, bubbles: false });
            window.dispatchEvent(event);
        }
    }

    /**
     * Track filter
     */
    public trackFilter(eventLabel: string, eventAction: string) {
        this.push({
            event: 'serverEvent',
            eventCategory: 'filter',
            eventAction,
            eventLabel,
        });
    }

    /**
     * Track map search
     */
    public trackMapSearch(eventLabel: string, eventAction: 'results' | 'no results' = 'no results') {
        this.push({
            event: 'serverEvent',
            eventCategory: 'dealer_search',
            eventAction,
            eventLabel,
        });
    }

    public trackAutoUncle(action: string) {
        this.push({
            event: 'serverEvent',
            eventCategory: 'tradeincar',
            eventAction: action,
            eventLabel: '-',
            eventValue: void 0,
        });
    }

    public trackAutoProff(action: string, email?: string) {
        const eventObject: AppGtmDataLayer = {
            event: 'serverEvent',
            eventCategory: 'tradeincar',
            eventAction: action,
            eventLabel: '',
            lead_menu: !!this.actionBarConversionInProgress,
        };
        if (email) {
            eventObject.email = sha256(email);
        }
        this.push(eventObject);
    }

    /**
     * Track favorites
     */
    public trackFavorite(eventLabel: 'add' | 'remove', { make: brand, series, model, bodyType }: Favorite & { bodyType?: string }) {
        this.push({
            event: 'serverEvent',
            eventCategory: 'favorites',
            eventAction: 'toggle',
            eventLabel,
            model,
            brand,
            ...(bodyType ? { type: bodyType } : {}),
            ...(brand?.toLowerCase().trim() === 'bmw' ? { series } : {}), // Only track series if brand is BMW
        });
    }

    /**
     * Track Action Bar
     * Open/Close menu on mobile
     */
    public trackActionBarExpand(isExpanded: boolean) {
        this.push({
            event: 'lead_menu_interaction_mobile',
            mobile_action: isExpanded ? 'open' : 'close',
        });
    }

    /**
     * Track Action Bar
     * Clicks on items
     */
    public trackActionBarClick(lead: 'test_drive' | 'build_car' | 'trade_in_car' | 'newsletter' | 'chat') {
        this.push({
            event: 'lead_menu_interaction_click',
            lead_menu_name: lead,
        });
    }

    /**
     * Track Action Bar
     * Conversion / Form submits
     */
    public trackActionBarConversion(lead: 'test_drive' | 'newsletter') {
        this.push({
            event: 'lead_menu_conversion',
            lead_menu_name: lead,
            // to send the newsletter event once the user has given consent
            newsletter: lead === 'newsletter',
        });
        this.actionBarConversionInProgress = undefined;
    }

    /**
     * Track Configurator
     * Tab / Step
     */
    public TrackConfiguratorStep(step: string, model: string) {
        this.push({
            event: 'car_configurator',
            step,
            car_model: model,
        });
    }

    /**
     * Track Configurator
     * Conversion / Form submits
     */
    public TrackConfiguratorSubmit(
        eventAction: 'print order' | 'conversion',
        eventLabel: 'send to me' | 'send to dealer',
        email: string,
        model: string
    ) {
        this.push({
            event: 'serverEvent',
            eventCategory: 'order car',
            eventAction,
            eventLabel,
            email: sha256(email),
            car_model: model,
            lead_menu: false,
        });
    }

    /**
     * Wraps basic push function gtm.js. Use this instead of accessing window.dataLayer directly.
     *
     * @param {object} gtmDataLayerObj - Standard GTM dataLayer object format.
     * @param {boolean} immediate - Don't defer pushing data until GTM has been initialized (default: false).
     */
    private push(gtmDataLayerObj: AppGtmDataLayer, immediate?: boolean) {
        if (this.featureDetection.isBrowser()) {
            if (!this.appWindow.google_tag_manager && gtmDataLayerObj.eventCallback) {
                // Ensure callback fires even if tracking is disabled or blocked.
                gtmDataLayerObj.eventCallback();
                gtmDataLayerObj.eventCallback = undefined;
            }

            if (this.appWindow.dataLayer) {
                if (!immediate) {
                    this.isGtmLoaded$
                        .pipe(
                            takeWhile((value) => !value, true),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((isLoaded) => {
                            if (isLoaded) {
                                this.appWindow.dataLayer.push(gtmDataLayerObj);
                            }
                        });
                } else {
                    this.appWindow.dataLayer.push(gtmDataLayerObj);
                }
            }
        }
    }

    /**
     * Pushes the start event.
     */
    private setGtmStart() {
        this.push({
            'event': 'gtm.js',
            'gtm.start': new Date().getTime(),
        });
    }

    private _initGtm(gtmId: string): void {
        if (this.featureDetection.isServer() || this.featureDetection.isAudit()) {
            return;
        }

        if (!gtmId) {
            console.warn('GTM is missing an access key');
            return;
        }

        // Check if dataLayer has been setup. (Setup is done on-page in <head> to ensure compatibility with various GTM testing tools)
        // window.dataLayer should not be used directly on-page other than this one time though.
        if (!this.appWindow.dataLayer) {
            return;
        }

        // Push start info
        this.setGtmStart();

        // Load gtm script
        this.scriptLoadService.loadScript('https://www.googletagmanager.com/gtm.js?id=' + gtmId, {
            callback: () => {
                if (!this.appWindow.google_tag_manager) {
                    console.warn('INFO: GTM is being prevented from loading (likely because of an ad-blocker).');
                } else {
                    this.isGtmLoaded$.next(true);
                    if (!this.isCookieInformationLoaded$.value) {
                        this.isCookieInformationLoaded$.next(true);
                    }
                }
            },
            async: true,
        });
    }

    private _initHotjar(hjId: number, hjSv: number): void {
        if (this.featureDetection.isServer() || this.featureDetection.isAudit()) {
            return;
        }

        if (this.isHjLoaded$.value) {
            console.warn('[Hotjar] already loaded');
            return;
        }

        if (!hjId) {
            console.warn('[Hotjar] Missing `hjId`');
            return;
        }

        if (!this.appWindow.hj) {
            console.warn('[Hotjar] `hj` is not defined. Please ensure that it has been defined in the <head> section.');
            return;
        }

        this.appWindow._hjSettings = {
            hjid: hjId,
            hjsv: hjSv,
        };

        this.scriptLoadService.loadScript(`https://static.hotjar.com/c/hotjar-${hjId}.js?sv=${hjSv}`, {
            callback: () => {
                if (!this.appWindow.hjSiteSettings) {
                    console.warn('[Hotjar] Looks like Hotjar is being prevented from loading.');
                }
                this.isHjLoaded$.next(true);
            },
            async: true,
        });
    }

    private _initClarity(clarityId: string): void {
        if (!clarityId || this.featureDetection.isServer() || this.featureDetection.isAudit()) {
            return;
        }

        if (this.isClarityLoaded$.value) {
            console.warn('[Clarity] already loaded');
            return;
        }

        // Define queue initially (part of embed script).
        // If not here the clarity.js file will not load.
        // The first embed script just loads another embed script (for clarity.js) with the options configured in dashboard... sigh.
        this.appWindow.clarity =
            this.appWindow.clarity ||
            ((...args: any[]) => {
                (this.appWindow.clarity.q = this.appWindow.clarity.q || []).push(args);
            });

        this.scriptLoadService.loadScript(`https://www.clarity.ms/tag/${clarityId}`, {
            callback: () => {
                this.isClarityLoaded$.next(true);
            },
            async: true,
        });
    }

    private _initCookieInformation(lang: string): void {
        if (this.featureDetection.isServer() || this.featureDetection.isAudit()) {
            return;
        }

        this.scriptLoadService.loadScript('https://policy.app.cookieinformation.com/uc.js', {
            callback: () => {
                if (!this.appWindow.CookieInformation) {
                    console.warn('INFO: CookieInformation is being prevented from loading (likely because of an ad-blocker).');
                } else {
                    this.isCookieInformationLoaded$.next(true);
                }
            },
            id: 'CookieConsent',
            type: 'text/javascript',
            dataset: {
                culture: lang.toUpperCase(),
            },
        });
    }

    /**
     * Initialize Application Insights JS
     */
    private initApplicationInsights(instrumentationKey: string) {
        if (!this.featureDetection.isBrowser() || this.featureDetection.isAudit()) {
            return;
        }

        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                distributedTracingMode: DistributedTracingModes.AI,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                disableCookiesUsage: true,
                extensions: [angularPlugin as any],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router },
                },
            },
        });

        this.appInsights.loadAppInsights();
        this.addTelemetryFilters();
    }

    /**
     * Filters out errors that match the 'regexList'
     *
     * ResizeObserver filter can probably be removed if this is fixed: https://bugs.chromium.org/p/chromium/issues/detail?id=809574
     * getConsentGivenFor error can be removed if GTM-partner fixes their stuff (check console in production)
     * CookieInformation related stuff should be removed if/when we start loading it ourselves.
     */
    private addTelemetryFilters(): void {
        if (this.appInsights) {
            const regexList: RegExp[] = [
                /ResizeObserver loop/i,
                /getConsentGivenFor/i,
                /CookieInformation/i,
                /CookieConsent/i,
                /btOpenWidget/i,
                /Request failed with status code 301/i,
            ];
            const aiTelemetryFilter = (envelope: ITelemetryItem) =>
                !Object.values(envelope.data ?? {}).find((field) => typeof field === 'string' && regexList.some((x) => x.test(field)));
            this.appInsights.addTelemetryInitializer(aiTelemetryFilter);
        }
    }

    /**
     * Initialise logic to capture UTM strings in the location path
     */
    private initUtm(): void {
        if (this.featureDetection.isAudit() || this.featureDetection.isServer()) {
            return;
        }

        let path = window.location.search;
        if (path.length && path[0] === '?') {
            path = path.slice(1);
        }

        const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];
        const utmString = pick(path, utmKeys);

        this.utm$.next(utmString);
    }

    /**
     * Get href
     */
    private getHref(): string | undefined {
        if (typeof window !== 'undefined') {
            return window.location.href;
        }

        const doc = this.document;
        return doc?.location ? doc.location.href : undefined;
    }

    private addCookieInformationEvent() {
        if (this.featureDetection.isServer() || this.featureDetection.isAudit()) {
            return;
        }

        this.appWindow.addEventListener(
            'CookieInformationConsentGiven',
            () => {
                this.functionalCookieConsent$.next(Boolean(CookieInformation.getConsentGivenFor('cookie_cat_functional')));
                this.marketingCookieConsent$.next(Boolean(CookieInformation.getConsentGivenFor('cookie_cat_marketing')));
                this.statisticCookieConsent$.next(Boolean(CookieInformation.getConsentGivenFor('cookie_cat_statistic')));
            },
            false
        );
    }
}
