import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { HeroSpot, IImage } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { MetaService } from '../../core/meta.service';
import { DesktopAspectRatio, MobileAspectRatio } from '../../utils/helpers/aspect-ratio';
import { breakpointQueries } from '../../utils/helpers/breakpoints';
import { IImageOptions, ImageUrl } from '../../utils/helpers/image-helper';
import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';

type HeroBreakpointKeys = 'mobile' | 'mobileLarge' | 'tablet' | 'desktop';
type HeroMediaSizes = { [key in HeroBreakpointKeys]?: string };
type HeroImageSrcSets = { [key in HeroBreakpointKeys]?: string };
interface HeroImageOptions {
    mobile: IImageOptions;
    desktop: IImageOptions;
}

/**
 * ### Note for content editors
 *
 * __The optimal image size and format:__
 *
 * Width:         `3100px`<br>
 * Height:        `1329px`<br>
 * Aspect ratio:  `21:9`<br>
 * Filetype:      `jpg` | `jpeg`
 *
 * __Optimal video size and format:__
 *
 * Aspect ratio:  `21:9`<br>
 * Filetype:      `mp4`<br>
 * Filesize:      `As small as possible`
 *
 */
@Component({
    selector: 'ncg-hero-spot',
    template: `
        <div [ngClass]="{ 'container is-full': !allowNarrowContent }" *ngIf="data" ncgLoadIn>
            <div
                [ngClass]="{
                    'container is-fullwidth': !spotsConfig.isHeroFullViewport && !allowNarrowContent,
                    'is-full': spotsConfig.isHeroFullViewport && !allowNarrowContent
                }"
            >
                <div class="hero-spot">
                    <ncg-link *ngIf="data.link" [link]="data.link" [title]="data.linkHoverTitle" styleClass="hero-spot__link"></ncg-link>
                    <ncg-video
                        class="hero-spot__media-container"
                        [ngClass]="{ 'hero-spot__media-container--media-filter': data.mediaFilter }"
                        *ngIf="data.video && data.video?.url; else imageTmpl"
                        [video]="data.video"
                        [poster]="data.image"
                        [preload]="isFirstSpot ? 'auto' : 'metadata'"
                    ></ncg-video>
                    <ng-template #imageTmpl>
                        <picture
                            *ngIf="data.image as image"
                            class="hero-spot__media-container"
                            [ngClass]="{ 'hero-spot__media-container--media-filter': data.mediaFilter }"
                        >
                            <source [media]="mediaSizes.desktop" [srcset]="imageSrcSets.desktop" />
                            <source [media]="mediaSizes.tablet" [srcset]="imageSrcSets.tablet" />
                            <source [media]="mediaSizes.mobileLarge" [srcset]="imageSrcSets.mobileLarge" />
                            <source [media]="mediaSizes.mobile" [srcset]="imageSrcSets.mobile" />
                            <ng-container *ngIf="!isFirstSpot; else firstSpotImageTmpl">
                                <img [src]="imageUrl" [alt]="image.altText || data.title || ''" loading="lazy" ncgImageLoad />
                            </ng-container>
                            <ng-template #firstSpotImageTmpl>
                                <img [src]="imageUrl" [alt]="image.altText || data.title || ''" fetchpriority="high" />
                            </ng-template>
                        </picture>
                    </ng-template>
                    <div class="hero-spot__content" ncgLoadIn *ngIf="data.title || data.subtitle || data.link">
                        <ncg-spot-content [useAsHeader]="this.data.useAsHeader" [title]="data?.title" [subtitle]="subtitle"></ncg-spot-content>
                        <div class="hero-spot__cta" *ngIf="data.link">
                            <ncg-button [link]="data.link" [linkHoverTitle]="data.linkHoverTitle" [buttonClass]="'is-hero'"></ncg-button>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="
                            data.legal &&
                            (data.legal.energyLabels || data.legal.legalText || data.additionalLegal?.length || data.legal.extraHeroText)
                        "
                    >
                        <div class="hero-spot__overlay"></div>
                        <div
                            class="hero-spot__legal"
                            ncgLoadIn
                            [ngClass]="{
                                'legal-text-moved': data?.showLegalTextUnderMobile,
                                'legal-text-moved-desktop': data?.showLegalTextUnderDesktop
                            }"
                        >
                            <ncg-legal
                                [energyLabels]="data.legal.energyLabels"
                                [legalText]="'<b>' + data.legal.modelName + '</b> ' + ' ' + data.legal.legalText"
                            ></ncg-legal>
                            <ng-container *ngFor="let item of data.additionalLegal" class="extra-legal">
                                <ncg-legal
                                    [energyLabels]="item.energyLabels"
                                    [legalText]="'<b>' + item.modelName + '</b> ' + ' ' + item.legalText"
                                ></ncg-legal>
                            </ng-container>
                            <ncg-legal [legalText]="data.legal.extraHeroText"></ncg-legal>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'hero';
    @Input() data: HeroSpot;
    public subtitle?: string;
    public imageSrcSets: HeroImageSrcSets;
    public mediaSizes: HeroMediaSizes;
    public imageUrl: string;
    public desktopAspectRatio: number = DesktopAspectRatio;
    public mobileAspectRatio: number = MobileAspectRatio;
    @Input() showLegalTextUnderHero: boolean = false;
    @Input() showLegalTextUnderDesktop: boolean = false;

    public legalTextPosition?: string;
    public legalTextPositionDesktop?: string;

    constructor(
        private readonly translate: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private readonly metaService: MetaService,
        @Inject(SpotsConfig) public readonly spotsConfig: SpotsConfig
    ) {
        super();
    }

    ngOnInit() {
        if (this.data) {
            this.subtitle = this.data.subtitle || '';

            if (this.data.price) {
                lastValueFrom(this.translate.get('models.pricefrom', { price: this.data.price })).then((priceFrom) => {
                    this.subtitle += ` ${priceFrom}`;
                    this.cd.markForCheck();
                });
            }

            if (this.data.video && this.data.video?.url && this.isFirstSpot) {
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload
                // "video preloading is included in the Preload spec, but is not currently implemented by browsers."
                // But once it is, this should improve performance
                this.metaService.setPreloadResource(this.data.video.url, 'video');
            } else if (this.data.image) {
                this.mediaSizes = breakpointQueries;
                this.imageUrl = this.getImageUrl(this.data.image);
                this.imageSrcSets = this.getImageSrcSets(this.data.image);

                if (!this.isFirstSpot) {
                    return;
                }

                // Preload images if first spot
                Object.keys(this.imageSrcSets).forEach((key) => {
                    const breakpoint = key as HeroBreakpointKeys;
                    this.metaService.setPreloadImage(this.imageSrcSets[breakpoint] as string, this.mediaSizes[breakpoint]);
                });
                this.metaService.setPreloadImage(this.imageUrl as string, this.mediaSizes.desktop);
            }
        }
        this.legalTextPosition = this.data.showLegalTextUnderMobile ? 'legal-text-moved' : '';
        this.legalTextPositionDesktop = this.data.showLegalTextUnderDesktop ? 'legal-text-moved-desktop' : '';
    }

    private getDefaultImageOptions(): HeroImageOptions {
        return {
            mobile: { heightratio: this.mobileAspectRatio, mode: 'crop' },
            desktop: { heightratio: this.desktopAspectRatio, mode: 'crop' },
        };
    }

    private getImageSrcSets(image: IImage): HeroImageSrcSets {
        const { mobile: mobileOptions, desktop: desktopOptions } = this.getDefaultImageOptions();
        const { mobile, mobileLarge, tablet, desktop } = this.getHeroImageWidths();

        return {
            mobile: ImageUrl(image, { width: mobile, ...mobileOptions }, 2),
            mobileLarge: ImageUrl(image, { width: mobileLarge, ...mobileOptions }, 2),
            tablet: ImageUrl(image, { width: tablet, ...desktopOptions }, 2),
            desktop: ImageUrl(image, { width: desktop, ...desktopOptions }, 2),
        };
    }

    private getImageUrl(image: IImage): string {
        return ImageUrl(image, { width: 1550, ...this.getDefaultImageOptions().desktop });
    }

    // To ensure best alignment with storybook, widths are defined here as a public method
    public getHeroImageWidths() {
        return {
            mobile: 394,
            mobileLarge: 767,
            tablet: 1024,
            desktop: 1550,
        };
    }
}
