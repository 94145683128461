import { CarModelV2, IConfiguratorCategory, IConfiguratorModelPageResponse } from '@ncg/data';
import { createAction, props } from '@ngrx/store';
import {
    ConfiguratorError,
    ConfiguratorStep,
    ConfiguratorTab,
    ConfiguratorTabDirection,
    ConfiguratorTabState,
} from '../../configurator/configurator';

export const setPageData = createAction('[configurator] Page data', props<{ pageData: IConfiguratorModelPageResponse }>());
export const setCategories = createAction('[configurator] Set categories', props<{ categories: IConfiguratorCategory[] }>());
export const setModel = createAction('[configurator] Set Model', props<{ model: CarModelV2 }>());
export const setStep = createAction('[configurator] Set step', props<{ step: ConfiguratorStep }>());
export const setTab = createAction('[configurator] Set tab', props<{ tab: ConfiguratorTab; isInitial?: boolean }>());
export const navigateTab = createAction('[configurator] Navigate tab', props<{ direction: ConfiguratorTabDirection }>());
export const setCurrency = createAction('[configurator] Set currency', props<{ currency: string }>());
export const softReset = createAction('[configurator] Reset state to default trim and powertrain');
export const reset = createAction('[configurator] Reset state');
export const setError = createAction('[configurator] Set error', props<{ error?: ConfiguratorError }>());
export const setTabState = createAction('[configurator] Set tab state', props<{ tab: ConfiguratorTab; tabState: ConfiguratorTabState }>());
