import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IModelPageResponse } from '@ncg/data';
import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-model-page',
    template: `
        <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
        <ncg-model-page-header [modelPage]="data" *ngIf="!data.hideDefaultModelPageNavigation"></ncg-model-page-header>
        <ng-container *ngIf="schema">
            <ngx-json-ld [json]="schema"></ngx-json-ld>
        </ng-container>
        <div class="model-page content" *ngIf="data">
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageComponent implements OnInit {
    static ref = 'modelPage';

    @Input()
    public data: IModelPageResponse;

    public schema: any;

    constructor(private readonly cd: ChangeDetectorRef) {}

    public ngOnInit() {
        this.setJsonLd();
    }

    private setJsonLd(): void {
        if (!this.data?.vehicleData) {
            return;
        }

        const vehicle = this.data.vehicleData;

        this.schema = {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            'mainEntity': {
                '@type': 'Product',
                'name': `${vehicle.brand} ${vehicle.model}`,
                'brand': {
                    '@type': 'Brand',
                    'name': vehicle.brand,
                },
                'model': vehicle.model,
                'isRelatedTo': {
                    '@type': 'Vehicle',
                    'name': `${vehicle.brand} ${vehicle.model}`,
                    'manufacturer': { '@type': 'Organization', 'name': `${vehicle.brand}` },
                    'fuelType': vehicle.fuelType,
                    // 'modelDate': '2024', // TODO: Date missing
                    // 'bodyType': 'Crossover', // TODO: BodyType missing
                    // 'numberOfDoors': 5, // TODO: Doors missing
                    // 'vehicleSeatingCapacity': 5, // TODO: Seating missing
                },
            },
        };

        if (this.data.meta?.description) {
            this.schema.description = this.data.meta.description;
        }

        if (this.data.meta?.seoImage?.url) {
            this.schema.image = ImageUrl(this.data.meta.seoImage, { width: 92, height: 92, mode: 'crop' });
        } else if (this.data.image?.url) {
            this.schema.image = ImageUrl(this.data.image, { width: 92, height: 92, mode: 'crop' });
        }

        if (vehicle.price?.price) {
            this.schema.offers = {
                '@type': 'Offer',
                'priceCurrency': vehicle.price?.currency,
                'price': vehicle.price?.price,
                'availability': 'https://schema.org/InStock',
            };
        } else {
            this.schema.offers = {
                '@type': 'Offer',
                'availability': 'LimitedAvailability',
            };
        }

        if (vehicle.engineName) {
            this.schema.mainEntity.isRelatedTo.vehicleEngine = vehicle.engineName;
        }
        this.cd.markForCheck();
    }
}
