import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { setTabState } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../../components/configurator-image/configurator-image.component';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { DatasByIdPipe } from '../../utils/datas-by-id.pipe';
import { FormatMultiParamPipe } from '../../utils/format-multi-param.pipe';
import { MultiParamActiveStatePipe } from '../../utils/multi-param-active-state.pipe';
import { ResourceFieldsByVariantModelPipe } from '../../utils/resource-fields-by-variant-model.pipe';
import { SortByPricePipe } from '../../utils/sort-by-price.pipe';

@Component({
    selector: 'ncg-configurator-optionals',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <div class="container" *ngIf="configuratorFacade.variant$ | async as variant" [@listAnimation]="variant.optionalOptionIds?.length">
                <ng-container *ngIf="configuratorFacade.model$ | async as model">
                    <p class="configurator__notification" *ngIf="!variant.optionalOptionIds?.length">
                        {{ 'configurator.tab_content_no_options' | translate }}
                    </p>
                    <div class="configurator__cards">
                        <ng-container
                            *ngFor="
                                let optionalsId of variant.optionalOptionIds
                                    | sortByPrice: model.optionalOptions : 'OptionPriceRetailSellingPrice' : 'desc'
                                    | async
                            "
                        >
                            <ncg-card
                                class="configurator__card"
                                *ngIf="model.optionalOptions?.[optionalsId] as option"
                                [selected]="(optionalsId | multiParamActiveState: params.optionals) === 'active'"
                                variant="checkbox"
                                contentClass="configurator__card-content content"
                            >
                                <ncg-configurator-image
                                    class="mb-5"
                                    [fields]="option.resources | resourceFieldsByVariantModel | async"
                                    sizes="300px"
                                    card-image
                                ></ncg-configurator-image>
                                <h2 class="configurator__label card-label" card-label>
                                    <a
                                        class="configurator__font-weight--emphasis configurator__link-cover configurator__word-break"
                                        (click)="configuratorFacade.dispatch(setTabState({ tab: 'optionals', tabState: 'visited' }))"
                                        [routerLink]="[]"
                                        [replaceUrl]="true"
                                        [queryParams]="{ optionals: option.id | formatMultiParam: params.optionals }"
                                        queryParamsHandling="merge"
                                    >
                                        <span
                                            *ngFor="let title of option.fields | datasById: 'OptionPriceMarketingTitle'"
                                            [innerHTML]="title | safe: 'html'"
                                        >
                                        </span>
                                    </a>
                                </h2>
                                <p
                                    class="configurator__text"
                                    *ngFor="let description of option.fields | datasById: 'OptionPriceMarketingDescription'"
                                >
                                    {{ description }}
                                </p>
                                <p class="configurator__text-tiny" *ngFor="let infoText of option.fields | datasById: 'OptionPriceInfoText'">
                                    {{ infoText }}
                                </p>
                                <div class="configurator__price price" *ngIf="configuratorFacade.showPrices$ | async">
                                    {{ (option.fields | datasById: ('OptionPriceRetailSellingPrice' | addCurrency | async))?.[0] | currency }}
                                </div>
                            </ncg-card>
                        </ng-container>
                    </div>
                    <div class="configurator__legal">
                        <p>
                            <strong>{{ variant.fields | dataById: 'VariantMarketingTitle' }}.</strong>
                            {{ variant.fields | dataById: 'VariantLegalText' }}<br />
                            {{ variant.fields | dataById: 'VariantLegalTextConfigurator' }}
                        </p>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: [
        `
            .card-label {
                margin: 0.2rem 0 0;
            }
            .price {
                text-align: right;
            }
        `,
    ],
    imports: [
        CommonModule,
        CardComponent,
        DataByIdPipe,
        DatasByIdPipe,
        CurrencyFormatterPipe,
        RouterModule,
        IconSpriteModule,
        MultiParamActiveStatePipe,
        FormatMultiParamPipe,
        ConfiguratorImageComponent,
        AddCurrencyPipe,
        SortByPricePipe,
        UtilsModule,
        ResourceFieldsByVariantModelPipe,
        TranslateModule,
    ],
})
export class ConfiguratorOptionalsComponent {
    public readonly setTabState = setTabState;
    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}
}
