import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, UrlSegment } from '@angular/router';
import { IErrorPageResponse, IRedirectResponse, PageResponse } from '@ncg/data';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest, Response as ExpressResponse } from 'express';
import { combineLatest, EMPTY, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { GlobalStateService, GlobalStateValue } from '../core/global-state.service';
import { PreviewService } from '../core/preview.service';
import { SettingsService } from '../core/settings.service';
import { PageTransitionService } from '../page-transition/page-transition.service';

// NOTE: In BFF these come from nestjs' HttpStatus enum (this matches naming for easy search).
const enum HttpStatus {
    OK = 200,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    NOT_FOUND = 404,
    GONE = 410,
    INTERNAL_SERVER_ERROR = 500,
}

@Injectable({ providedIn: 'root' })
export class PageResolve implements Resolve<PageResponse>, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    private _currentCulture: string;
    private readonly _apiUrl = '/api/page/url';
    private readonly _apiUrlById = '/api/page/id';
    private readonly template$: ReplaySubject<PageResponse['template']> = new ReplaySubject<PageResponse['template']>(1);

    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService,
        private readonly globalStateService: GlobalStateService,
        private readonly pageTransitionService: PageTransitionService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly previewService: PreviewService,
        private readonly router: Router,
        private readonly globalState: GlobalStateService,
        @Optional()
        @Inject(REQUEST)
        private readonly request?: ExpressRequest,
        @Optional()
        @Inject(RESPONSE)
        private readonly response?: ExpressResponse
    ) {
        // Set site state when page transition is covering the screen (mid), to avoid visible layout shifts
        combineLatest([this.pageTransitionService.phase$, this.template$])
            .pipe(
                // set initial state by checking if first emit
                concatMap((value, index) => (index === 0 ? of(value).pipe(tap(([, template]) => this._setGlobalState(template))) : of(value))),
                filter(([phase]) => phase === 'mid'),
                takeUntil(this.unsubscribe)
            )
            .subscribe(([, template]) => {
                this._setGlobalState(template);
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<PageResponse> {
        const sanitizedUrl = route.url.length === 0 ? '/' : this._sanitizeUrlSegment(route.url);

        /**
         * In case of preview mode, all URLs should be numeric and we should include an Authorization header
         */
        this.previewService.setPreviewFromContext(route);
        const previewContext = this.previewService.getPreviewContextSnapshot();
        let id = '';

        if (previewContext) {
            const pageId = sanitizedUrl.slice(1);
            const hasId = /^[0-9]+$/.test(pageId);
            // If the page is not published, we must resolve by id
            id = hasId ? pageId : '';
        }

        return this.http
            .get<PageResponse>(previewContext && id ? `${this._apiUrlById}/${id}` : this._apiUrl, {
                ...(previewContext
                    ? {
                          headers: {
                              Authorization: `${previewContext?.token}`,
                          },
                          withCredentials: true,
                          params: {
                              url: sanitizedUrl,
                              culture: previewContext.culture,
                              preview: '1',
                          },
                      }
                    : {
                          params: {
                              url: sanitizedUrl,
                          },
                      }),
            })
            .pipe(
                // PageResponse modifications
                switchMap((page) => this._nullPage(page, sanitizedUrl)),
                tap((page) => {
                    this._currentCulture = page.culture;
                    this.globalState.setState('activePageResponse', page);
                }),
                tap(({ template }) => this.template$.next(template)),
                switchMap((page) => this._linkPage(page)),
                // Handle 404 and server errors
                catchError((err: AppHttpErrorResponse) => {
                    if (err?.error?.status) {
                        switch (err.error.status) {
                            case HttpStatus.NOT_FOUND:
                                return this._notFoundPage();

                            case HttpStatus.GONE:
                                if (err.error.error && this._isRedirectResponse(err.error.error)) {
                                    const redirectResponse = err.error.error;
                                    return this._redirectResponse(redirectResponse.location, redirectResponse.statusCode);
                                }
                                break;
                        }
                    }

                    return this._errorPage();
                })
            );
    }

    private _sanitizeUrlSegment(url: UrlSegment[]) {
        return url.reduce((acc, segment) => `${acc}/${segment.path}`, '');
    }

    private _isRedirectResponse(obj: any): obj is IRedirectResponse {
        return !!(obj as IRedirectResponse).location;
    }

    private _redirectResponse(location: string, statusCode: number = HttpStatus.MOVED_PERMANENTLY, preserveQuery = true): Observable<never> {
        const isAbsoluteUrl = location.startsWith('http');

        // Add query parameters if they are not present
        if (preserveQuery && location.indexOf('?') === -1) {
            let search = '';

            if (this.request) {
                const searchSplit = this.request.url.split('?');
                if (searchSplit.length > 1) {
                    search = `?${searchSplit[1]}`;
                }
            } else if (this.featureDetectionService.isBrowser()) {
                search = window.location.search;
            }

            location += search;
        }

        // Browser
        if (this.featureDetectionService.isBrowser()) {
            if (isAbsoluteUrl) {
                window.location.href = location;
            } else {
                this.router.navigateByUrl(location);
            }
        }

        // Server
        else if (this.request && this.response) {
            location = isAbsoluteUrl ? location : `${this.request.protocol}://${this.request.get('host')}${location}`;
            if (this.response.writableEnded) {
                const req: any = this.request;
                req._r_count = Number(req._r_count || 0) + 1;

                console.warn('Attempted to redirect on a finished response. From', this.request.url, 'to', location);

                if (req._r_count > 10) {
                    console.error('Detected a redirection loop. killing the nodejs process');
                    process.exit(1);
                }
            } else {
                this.response.redirect(statusCode, location);
                this.response.end();
            }
        } else {
            return throwError(() => new Error(`Something went wrong and could not redirect to ${location}`));
        }

        return EMPTY;
    }

    private _nullPage(page: PageResponse, context: string): Observable<PageResponse> {
        if (!page) {
            console.warn('Received null object from server with context', context);
            return this._errorPage();
        }

        return of(page);
    }

    private _linkPage(page: PageResponse): Observable<PageResponse> {
        // Make sure link pages are not accessible
        if (page.template === 'linkPage' || page.template === 'modelLinkPage') {
            if (page.pageLink?.url) {
                return this._redirectResponse(page.pageLink.url);
            }
            return this._notFoundPage();
        }

        return of(page);
    }

    private _notFoundPage(): Observable<PageResponse> {
        const currentCulture$: Observable<string> = this._currentCulture
            ? of(this._currentCulture)
            : this.http
                  .get<PageResponse>(this._apiUrl, {
                      params: {
                          url: '/',
                      },
                  })
                  .pipe(
                      map((page) => page.culture),
                      catchError(() => 'da-DK')
                  );

        return currentCulture$.pipe(
            switchMap((culture) => {
                this.settingsService.initSettings(culture);
                return this.settingsService.get().pipe(take(1));
            }),
            switchMap((settings) => {
                if (!settings.notFoundPage?.url) {
                    return throwError(() => new Error('No not found page'));
                }

                return this.http.get<PageResponse>(this._apiUrl, {
                    params: {
                        url: settings.notFoundPage.url,
                    },
                });
            }),
            catchError(() => this._errorPage()),
            finalize(() => {
                if (this.response) {
                    this.response.status(HttpStatus.NOT_FOUND);
                }
            })
        );
    }

    private _errorPage(): Observable<PageResponse> {
        if (this.response) {
            this.response.status(HttpStatus.INTERNAL_SERVER_ERROR);
        }

        const errorPageData: IErrorPageResponse = {
            template: 'errorPage',
            parentId: null,
            hasChildren: false,
            id: 0,
            name: '',
            url: '',
            grid: [],
            culture: this._currentCulture || 'da-DK',
            meta: {
                title: 'Error - something went wrong',
                description: '',
                excludeFromRobots: true,
                includeInNavigation: false,
                includeInSearch: false,
                includeInFooter: false,
                hideInMenu: false,
            },
            updateDate: new Date().toISOString(),
            createDate: new Date().toISOString(),
            preview: false,
            published: true,
        };

        return of(errorPageData);
    }

    private _setGlobalState(template: PageResponse['template']): void {
        let flow: GlobalStateValue = 'default';
        switch (template) {
            case 'configuratorPage':
            case 'configuratorModelPage':
                flow = 'configurator';
                break;
            case 'serviceBookingPage':
                flow = 'service-booking';
                break;
        }

        this.globalStateService.setState('activeFlow', flow);
    }
}
