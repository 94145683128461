import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CommonModule } from '@angular/common';
import { ColorComponent } from '../../../color/color.component';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { Router, RouterModule } from '@angular/router';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { setTabState } from '../../../+state/configurator/configurator.actions';
import { FilterByIdPipe } from '../../utils/filter-by-ids.pipe';

@Component({
    selector: 'ncg-configurator-exterior',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <div
                class="container"
                *ngIf="configuratorFacade.variant$ | async as variant"
                [@listAnimation]="variant.commercialColourOptionExteriorIds?.length"
            >
                <div class="colors">
                    <div
                        class="color-wrapper"
                        *ngFor="
                            let exterior of variant.commercialColourOptionExteriorIds
                                | filterById
                                    : (configuratorFacade.model$ | async)?.commercialColourOptionsExterior
                                    : ('CommercialColourOptionRetailSellingPrice' | addCurrency | async)
                        "
                    >
                        <ncg-color
                            *ngIf="exterior"
                            [selected]="exterior.id === params.exterior"
                            [color]="exterior.fields | dataById: 'CommercialColourOptionHEXColourCode'"
                            [secondaryColor]="exterior.fields | dataById: 'CommercialColourOptionSecondaryHEXColourCode'"
                            (colorClick)="setSelectedColor(exterior.id)"
                        >
                            <span class="color-content configurator__text">
                                <span class="color-title configurator__text--bold">{{
                                    exterior.fields | dataById: 'CommercialColourOptionMarketingTitle'
                                }}</span>
                                <span>{{ exterior.fields | dataById: 'CommercialColourOptionPaintType' }}</span>
                                <span class="price" *ngIf="configuratorFacade.showPrices$ | async">{{
                                    (exterior.fields | dataById: ('CommercialColourOptionRetailSellingPrice' | addCurrency | async)) ?? 0 | currency
                                }}</span>
                            </span>
                        </ncg-color>
                    </div>
                </div>
                <div class="configurator__legal">
                    <!-- Not needed datawise, but the list animation does not trigger without this evaluation -->
                    <p *ngIf="variant.fields">
                        <strong>{{ variant.fields | dataById: 'VariantMarketingTitle' }}.</strong>
                        {{ variant.fields | dataById: 'VariantLegalText' }}<br />
                        {{ variant.fields | dataById: 'VariantLegalTextConfigurator' }}
                    </p>
                </div>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: [
        `
            .colors {
                display: grid;
                gap: 3.2rem;
                grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
            }
            .color-wrapper {
                display: flex;
                justify-content: center;
            }
            .color-content {
                display: inline-grid;
                gap: 0.3rem;
            }
            .color-title {
                font-size: max(var(--font-size-small), 1.3rem); /* BYD 'i' looks like 'l' if size is smaller than 13px */
            }
        `,
    ],
    imports: [CommonModule, ColorComponent, DataByIdPipe, AddCurrencyPipe, CurrencyFormatterPipe, RouterModule, FilterByIdPipe],
})
export class ConfiguratorExteriorComponent {
    constructor(
        private readonly router: Router,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    public setSelectedColor(id?: string | null): void {
        this.router.navigate([], { queryParams: { exterior: id }, queryParamsHandling: 'merge', replaceUrl: true });
        this.configuratorFacade.dispatch(setTabState({ tab: 'exterior', tabState: 'visited' }));
    }
}
